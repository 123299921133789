import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { firebaseConfig } from './firebase.config'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

export const Firebase = initializeApp(firebaseConfig)
export const db = getFirestore(Firebase)
export const analytics = getAnalytics(Firebase)
export const auth = getAuth()
export const Providers = { google: new GoogleAuthProvider() }
