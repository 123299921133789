import React, { useState } from 'react'
import { signInWithPopup } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { auth, Providers } from '../../config/firebase'
import { Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import GoogleIcon from '@mui/icons-material/Google'
import { useToast } from '../../hooks/use-toast'

const AuthContainer = () => {
  const navigate = useNavigate()
  const [disabled, setDisabled] = useState(false)
  const toast = useToast()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const lessThanMid = useMediaQuery(theme.breakpoints.down('md'))

  const signInWithGoogle = () => {
    setDisabled(true)
    signInWithPopup(auth, Providers.google)
      .then(() => {
        setDisabled(false)
        navigate('/')
      })
      .catch((error) => {
        toast.show('error', error.message)
        setDisabled(false)
      })
  }

  return (
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} justifyContent="center" container justifyItems="center">
          <Typography variant={isMobile ? 'h4' : 'h3'}>Let’s sign you in!</Typography>
        </Grid>
        <Grid item xs={lessThanMid ? 12 : 10} container justifyItems="center" justifyContent="center">
          <Button
              fullWidth={true}
              startIcon={<GoogleIcon/>}
              size="large"
              disabled={disabled}
              variant="outlined"
              onClick={signInWithGoogle}
              sx={{
                color: lessThanMid ? 'black' : null,
                backgroundColor: lessThanMid ? 'white' : null,
                borderColor: lessThanMid ? 'white' : null
              }}
          >Sign in with Google</Button>
        </Grid>
        <Grid item xs={10} justifyContent="center" justifyItems="center" container>
          <Typography variant="body2">By signing in, you agree with the Terms and
            Conditions</Typography>
          {/* todo add link to Terms and Conditions  */}
        </Grid>
      </Grid>
  )
}

export default AuthContainer
