import dayjs, { Dayjs } from 'dayjs'

export function getDaysArrayByMonth (date?: Dayjs, endDate?: Dayjs) {
  const startOfMonth = date ? date.startOf('month') : dayjs().startOf('month')
  const endOfMonth = endDate ?? (date ? date.endOf('month') : dayjs().endOf('month'))

  const arrDays = []
  let iterator = startOfMonth.clone()
  while (iterator.isBefore(endOfMonth, 'day') || iterator.isSame(endOfMonth, 'day')) {
    arrDays.push(iterator.clone())
    iterator = iterator.add(1, 'day')
  }

  return arrDays
}

export function isWeekend (day: Dayjs) {
  return day.day() === 0 || day.day() === 6
}
