import { Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import CsvDownloader from 'react-csv-downloader'
import { employeeService } from '../../service/employeeService'
import { defaultErrorHandler } from '../../util/errorHandler'
import { useToast } from '../../hooks/use-toast'
import { timesheetService } from '../../service/timesheetService'
import dayjs, { Dayjs } from 'dayjs'
import { groupTimesheetByEmployeeId } from '../../hooks/use-admin-timesheet'
import { Timesheet } from '../../model/Timesheet'
import { EmployeeStatus } from '../../model/Employee'
import { getDaysArrayByMonth, isWeekend } from '../../util/dateUtils'
import { TimeOffStatus, TimeOffType } from '../../model/TimeOff'
import { getTimeOffCount } from '../admin/EmployeeDailyHoursTable'
import { useEmployees } from '../../hooks/use-employees'
import { useAdminTimeOffs } from '../../hooks/use-admin-timeoffs'
import { usePublicHolidays } from '../../hooks/use-public-holidays'

const generateColumns = (startDate: Dayjs, endDate: Dayjs) => [
  { displayName: 'Id', id: 'id' },
  { displayName: 'Full Name', id: 'name' },
  { displayName: 'Validated', id: 'validated' },
  { displayName: 'Timeframe', id: 'timeframe' },
  ...getDaysArrayByMonth(startDate, endDate).map(d => ({
    displayName: d.format('MM/DD/YY'),
    id: d.format('MM/DD/YY')
  })),
  { displayName: 'Work Days', id: 'workDays' },
  { displayName: 'Total Days Off', id: 'daysOff' },
  { displayName: 'PTO', id: 'pto' },
  { displayName: 'Medical Leave', id: 'med' },
  { displayName: 'Covid Medical Leave', id: 'covidMed' },
  { displayName: 'Maternity Leave', id: 'mat' },
  { displayName: 'Paternity Leave', id: 'pat' },
  { displayName: 'Autorecenzare', id: 'census' },
  { displayName: 'Blood Donation', id: 'blood' },
  { displayName: 'Study Leave', id: 'study' },
  { displayName: 'Birthday', id: 'birthday' },
  { displayName: 'Marriage', id: 'marriage' },
  { displayName: 'Bereavement Leave', id: 'bereavement' },
  { displayName: 'ZLP', id: 'zlp' },
  { displayName: 'Adoption', id: 'adoption' }
]

function splitEmployeesIntoGroups<T>(array: T[], size: number = 10): T[][] {
  const arrays = [] as T[][]
  const a = [...array]

  while (a.length > 0) {
    arrays.push(a.splice(0, size))
  }

  return arrays
}

function formatDate(date: Dayjs): string {
  const day = date.date().toString().padStart(2, '0')
  const month = (date.month() + 1).toString().padStart(2, '0') // Month is 0-indexed
  const year = date.year().toString().substr(-2) // Get last two digits of year

  return `${month}/${day}/${year}`
}

export const ExcelExport: React.FC<{ startDate: Dayjs, endDate: Dayjs, contractType?: string, divisionName?: string }> = ({ startDate, endDate, contractType, divisionName }) => {
  const toast = useToast()
  const now = dayjs()
  const [loading, setLoading] = useState(false)
  const { employees } = useEmployees()
  const { timeOffs } = useAdminTimeOffs(employees.map(e => e.id), startDate.toDate(), endDate.toDate())
  const { publicHolidays } = usePublicHolidays()
  const handleExport = async (): Promise<any[]> => {
    const data: any[] = []

    try {
      setLoading(true)
      const { employees } = await employeeService.getActiveEmployees(contractType as EmployeeStatus, divisionName)
      const employeeGroups = splitEmployeesIntoGroups(employees)
      const allEmployeeTimesheet: Record<string, Timesheet[]> = {}
      for (const group of employeeGroups) {
        const timesheet = await timesheetService.retrieveTimeSheetForEmployees(
          group?.map(e => e.id),
          startDate.toDate(),
          endDate.toDate()
        )
        const groupedTimesheet = groupTimesheetByEmployeeId(timesheet)
        Object.assign(allEmployeeTimesheet, groupedTimesheet)
      }

      employees.forEach(e => {
        const row = {
          id: e.employeeNumber,
          name: `${e.lastName} ${e.firstName}`,
          validated: allEmployeeTimesheet[e.id]?.every(t => t.validated) ? 'Yes' : 'No',
          timeframe: e.employmentHistoryStatus === EmployeeStatus.PartTime ? '09:00 - 13:00' : '09:00 - 18:00',

          // OLD
          // ...getDaysArrayByMonth(startDate, endDate).reduce<Record<string, number>>((acc, d) => {
          //   const timesheet = allEmployeeTimesheet[e.id]?.find(t => dayjs(t.date).isSame(d, 'day'))
          //   // const isPastOrCurrentDay = d.isBefore(dayjs())

          //   if (timesheet) {
          //     if ((!timesheet.timeOff || timesheet.timeOff.status !== TimeOffStatus.approved) && !timesheet.publicHoliday) {
          //       acc[formatDate(d)] = isWeekend(d) ? 0 : e.employmentHistoryStatus === EmployeeStatus.PartTime ? 4 : 8
          //     } else {
          //       acc[formatDate(d)] = 0
          //     }
          //   } else {
          //     // Prefill future days with 8
          //     const publicHolidayz = publicHolidays.find(p => dayjs(p.date).isSame(d, 'day'))
          //     acc[formatDate(d)] =
          //       isWeekend(d) || publicHolidayz
          //         ? 0
          //         : timeOffs[e.id]?.some(t => t.status === TimeOffStatus.approved && formatDate(dayjs(t.date)) === formatDate(d))
          //           ? 0
          //           : e.employmentHistoryStatus === EmployeeStatus.PartTime ? 4 : 8
          //   }

          // Loop through each day in the date range
          ...getDaysArrayByMonth(startDate, endDate).reduce<Record<string, number>>((acc, d) => {
            const timesheet = allEmployeeTimesheet[e.id]?.find(t => dayjs(t.date).isSame(d, 'day'))
            const isWeekendDay = isWeekend(d)
            const publicHoliday = publicHolidays.find(p => dayjs(p.date).isSame(d, 'day'))
            const hasApprovedTimeOff = timeOffs[e.id]?.some(t => t.status === TimeOffStatus.approved && formatDate(dayjs(t.date)) === formatDate(d))
            const isTerminated = e.employmentHistoryStatus === 'Terminated'
            const isPastOrCurrentDay = d.isBefore(dayjs())

            if (isWeekendDay || publicHoliday || hasApprovedTimeOff || isTerminated) {
              // If the day is a weekend, public holiday, or doesn't have approved time off or is terminated contract
              acc[formatDate(d)] = 0
            } else {
              // Past day and no timesheet exists, for new joiners
              if (isPastOrCurrentDay && !timesheet) {
                acc[formatDate(d)] = 0
              } else {
                acc[formatDate(d)] = e.employmentHistoryStatus === EmployeeStatus.PartTime ? 4 : 8
              }
            }
            // }
            return acc
          }, {}),
          workDays: allEmployeeTimesheet?.[e.id]?.filter(t => !t.timeOff && !t.publicHoliday && !isWeekend(dayjs(t.date))).length || 0,
          daysOff: timeOffs?.[e.id]?.filter(t => t.status === TimeOffStatus.approved).length || 0,
          pto: getTimeOffCount(timeOffs?.[e.id], TimeOffType.PTO),
          med: getTimeOffCount(timeOffs?.[e.id], TimeOffType.MedicalLeave),
          covidMed: getTimeOffCount(timeOffs?.[e.id], TimeOffType.CovidMedicalLeave),
          mat: getTimeOffCount(timeOffs?.[e.id], TimeOffType.MaternityLeave),
          pat: getTimeOffCount(timeOffs?.[e.id], TimeOffType.PaternityLeave),
          census: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Autorecenzare),
          blood: getTimeOffCount(timeOffs?.[e.id], TimeOffType.BloodDonation),
          study: getTimeOffCount(timeOffs?.[e.id], TimeOffType.StudyLeave),
          birthday: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Birthday),
          marriage: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Marriage),
          bereavement: getTimeOffCount(timeOffs?.[e.id], TimeOffType.BereavementLeave),
          zlp: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Zlp),
          adoption: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Adoption)
        }
        data.push(row)
      })
    } catch (e) {
      defaultErrorHandler(e, toast)
    } finally {
      setLoading(false)
    }
    return data
  }

  const columns = useMemo(() => generateColumns(startDate, endDate), [startDate, endDate])
  return (
    <CsvDownloader filename={`export_${startDate.format('MMMM')}_${now.format('DD_MM_YY-HH_mm')}`}
      datas={handleExport} columns={columns}>
      <LoadingButton
        loading={loading}
        variant="contained"
        sx={{
          paddingY: 1,
          paddingX: 3,
          '& a': {
            textDecoration: 'none',
            color: theme => theme.palette.primary.contrastText
          }
        }}
      >
        <Typography variant="body2" fontWeight={700}>EXPORT EXCEL</Typography>
      </LoadingButton>
    </CsvDownloader>
  )
}
