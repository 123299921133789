import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import Selector from './Selector'
import dayjs from 'dayjs'
import { useYearMonths } from '../../hooks/use-year-months'
import { useEmployeeDivisions } from '../../hooks/use-employee-divisions'

export const YearMonthContractSelector: React.FC<{
  onChanged: (year?: string, month?: Date, contractType?: string, divisionName?: string) => void
}> = ({ onChanged }) => {
  const contractTypeOptions = ['All', 'Full-Time', 'Part-Time', 'Contractor', 'Terminated']
  const { divisions } = useEmployeeDivisions()
  const { yearMonths, lastMonth } = useYearMonths()

  const [selectedYear, setSelectedYear] = React.useState<string>()
  const [selectedMonth, setSelectedMonth] = React.useState<Date>()
  const [selectedContractType, setSelectedContractType] = React.useState<string>(contractTypeOptions[0])
  const [selectedEmployeeDivision, setSelectedEmployeeDivision] = React.useState<string>('All')
  const months = selectedYear ? yearMonths?.[selectedYear] ?? [] : []
  const years = Object.keys(yearMonths ?? [])

  useEffect(() => {
    if (!lastMonth) return

    onChanged(selectedYear, selectedMonth, selectedContractType, selectedEmployeeDivision)
  }, [selectedYear, selectedMonth, selectedContractType, selectedEmployeeDivision])

  useEffect(() => {
    if (lastMonth) {
      setSelectedYear(dayjs(lastMonth).format('YYYY'))
      setSelectedMonth(lastMonth)
    }
  }, [lastMonth])

  return (
        <Box>
            <Selector
                label="Year"
                options={years}
                onChange={setSelectedYear}
                selected={selectedYear ?? ''}
            />
            <Selector
                label="Month"
                options={months.map(m => dayjs(m).format('MMMM'))}
                onChange={m => {
                  setSelectedMonth(
                    months.find(m2 => dayjs(m2).format('MMMM') === m) ?? new Date()
                  )
                }}
                selected={dayjs(selectedMonth).format('MMMM')}
            />
            <Selector
                label="Type"
                options={contractTypeOptions}
                onChange={setSelectedContractType}
                selected={selectedContractType}
            />
            <Selector
                label="Division"
                options={divisions}
                onChange={setSelectedEmployeeDivision}
                selected={selectedEmployeeDivision}
            />
        </Box>
  )
}
