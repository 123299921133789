import { FirestoreDataConverter } from 'firebase/firestore'
import { DocumentData, QueryDocumentSnapshot, Timestamp } from '@firebase/firestore'
import { fromObjectToTimeOff, TimeOff, timeOffConverter } from './TimeOff'
import { fromObjectToPublicHoliday, PublicHoliday, publicHolidayConverter } from './PublicHoliday'

export interface ValidationPeriod {
  start: Date
  end: Date
}

export interface Timesheet {
  publicHoliday?: PublicHoliday
  timeOff?: TimeOff
  id: string
  date?: Date
  employeeId: string
  validated: boolean
  email?: string
  month: string
  validationDate?: Date
}

export const timesheetConverter:
FirestoreDataConverter<Timesheet> = {

  toFirestore: (timesheet: Timesheet): DocumentData => {
    return {
      id: timesheet.id,
      date: timesheet.date ? Timestamp.fromDate(timesheet.date) : null,
      validationDate: timesheet.validationDate ? Timestamp.fromDate(timesheet.validationDate) : null,
      employeeId: timesheet.employeeId,
      validated: timesheet.validated,
      email: timesheet.email ?? null,
      month: timesheet.month,
      publicHoliday: timesheet.publicHoliday ? publicHolidayConverter.toFirestore(timesheet.publicHoliday) : null,
      timeOff: timesheet.timeOff ? timeOffConverter.toFirestore(timesheet.timeOff) : null
    }
  },
  fromFirestore (
    snapshot: QueryDocumentSnapshot
  ): Timesheet {
    const data = snapshot.data()
    return {
      id: data?.id,
      date: data?.date?.toDate(),
      validationDate: data?.validationDate?.toDate(),
      employeeId: data?.employeeId,
      validated: data?.validated,
      email: data?.email,
      month: data?.month,
      publicHoliday: data?.publicHoliday ? fromObjectToPublicHoliday(data?.publicHoliday) : undefined,
      timeOff: data?.timeOff ? fromObjectToTimeOff(data.timeOff) : undefined
    }
  }

}
