import React from 'react'

const Center: React.FC<{
  children: React.ReactNode
  height?: string | number
}> = ({ children, height = 100 }) => {
  let useHeight
  if (typeof height === 'string') useHeight = height
  else useHeight = height.toString() + 'vh'

  return (
      <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: useHeight
          }}
      >
        {children}
      </div>
  )
}

export default Center
