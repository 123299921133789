import { FirestoreDataConverter } from 'firebase/firestore'
import { DocumentData, QueryDocumentSnapshot, Timestamp } from '@firebase/firestore'

export enum TimeOffType {
  PTO = 'PTO',
  MedicalLeave = 'MedicalLeave',
  CovidMedicalLeave = 'CovidMedicalLeave',
  Birthday = 'Birthday',
  Autorecenzare = 'Autorecenzare',
  BereavementLeave = 'BereavementLeave',
  BloodDonation = 'BloodDonation',
  Marriage = 'Marriage',
  MaternityLeave = 'MaternityLeave',
  PaternityLeave = 'PaternityLeave',
  StudyLeave = 'StudyLeave',
  Zlp = 'Zlp',
  Adoption = 'Adoption',
}

export enum TimeOffStatus {
  approved = 'approved',
  requested = 'requested',
  canceled = 'canceled',
  superceded = 'superceded',
  denied = 'denied',
}

export interface TimeOff {
  id: string
  date: Date
  employeeId: string
  type: TimeOffType
  status: TimeOffStatus
}

export function fromObjectToTimeOff (data: DocumentData): TimeOff {
  return {
    id: data?.id,
    date: data?.date?.toDate(),
    employeeId: data?.employeeId,
    type: data?.type,
    status: data?.status
  }
}

export const timeOffConverter:
FirestoreDataConverter<TimeOff> = {
  toFirestore (modelObject: TimeOff): DocumentData {
    return {
      id: modelObject.id,
      date: Timestamp.fromDate(modelObject.date),
      employeeId: modelObject.employeeId,
      type: modelObject.type,
      status: modelObject.status
    }
  },

  fromFirestore (snapshot: QueryDocumentSnapshot): TimeOff {
    return fromObjectToTimeOff(snapshot.data())
  }
}
