import { LoadingButton } from '@mui/lab'
import Typography from '@mui/material/Typography'
import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

export const ValidationConfirmationDialog: React.FC<{
  isMobile?: boolean
  disabled?: boolean
  loading?: boolean
  onSubmit: () => void
}> = ({ onSubmit, disabled, loading, isMobile }) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
        <>
            {isMobile
              ? (
                    <LoadingButton variant="contained"
                                   loading={loading}
                                   color="primary" sx={{
                                     position: 'fixed',
                                     zIndex: 2,
                                     bottom: 20,
                                     right: '2.5vw',
                                     padding: 2,
                                     width: '95vw',
                                     margin: 0
                                   }}
                                   onClick={handleClickOpen}
                                   disabled={disabled}>
                        <Typography variant="h4" fontWeight="bold">VALIDATE</Typography>
                    </LoadingButton>
                )
              : (
                    <LoadingButton variant="contained"
                                   sx={{ paddingX: 4, paddingY: 1, marginTop: 2, justifySelf: 'center' }}
                                   loading={loading}
                                   onClick={handleClickOpen}
                                   disabled={disabled}>
                        <Typography variant="body2" fontWeight="700">VALIDATE</Typography>
                    </LoadingButton>
                )}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Timesheet validation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure all your Time off requests are submitted and approved in BambooHR?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        size="medium"
                        onClick={handleClose}
                        variant="text"
                        sx={{ color: theme => theme.palette.mode === 'light' ? theme.palette.secondary.contrastText : 'inherit' }}>CANCEL</Button>
                    <Button color="primary" size="medium" variant="text" onClick={() => {
                      handleClose()
                      onSubmit()
                    }} autoFocus>
                        VALIDATE
                    </Button>
                </DialogActions>
            </Dialog>
        </>
  )
}
