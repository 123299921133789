import { GridColDef } from '@mui/x-data-grid'
import { Avatar, Box, Checkbox, Grid, Stack, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { getDaysArrayByMonth } from './dateUtils'
import clsx from 'clsx'
import { CellHeader } from '../components/employee/CellHeader'
import React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { TimeOff, TimeOffStatus, TimeOffType } from '../model/TimeOff'
import { customColors } from '../theme'
import { Timesheet } from '../model/Timesheet'
import { PublicHoliday } from '../model/PublicHoliday'

export function generateDailyHoursTableColumns(date: Dayjs, publicHolidays: PublicHoliday[], onValidate: (eId: string) => void, onInvalidate: (eId: string) => void): GridColDef[] {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      sortable: false,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      width: 260,
      renderCell: (params) =>
      (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {params.value[1] && (
            <Avatar alt={params.value[0]} src={params.value[1]} sx={{ mr: 1 }} />
          )}
          <Typography
            variant="body2"
          >
            {params.value[0]}
          </Typography>
        </Box>
      ),
      sortable: false,
      cellClassName: (params) => params.value[2] ? 'valid-cell' : 'invalid-cell'
    },
    {
      field: 'valid',
      headerName: 'Val.',
      align: 'center',
      width: 50,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const employeeId = params.value?.[2]
        const loading = params.value?.[1]
        if (loading) {
          return null
        }
        return (
          <Grid container justifyContent="center">
            <Grid item>
              <Checkbox
                checked={params.value[0]}
                onChange={(event, checked) => {
                  if (checked) {
                    onValidate(employeeId)
                  } else {
                    onInvalidate(employeeId)
                  }
                }}
                checkedIcon={<CheckCircleIcon />}
                icon={<RadioButtonUncheckedIcon />}
              />
            </Grid>
          </Grid>
        )
      }
    },
    {
      field: 'timeframe',
      headerName: 'Timeframe',
      width: 120
    }
  ]

  getDaysArrayByMonth(date).forEach((day) => {
    columns.push({
      field: day.format('Do'),
      headerName: day.format('Do'),
      width: 30,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: () => {
        const isPublicHoliday = publicHolidays.some((ph) => dayjs(ph.date).isSame(day, 'day'))
        if (isPublicHoliday) {
          return clsx('publicHolidayHeaderCell')
        }
        return clsx('')
      },
      renderHeader: () =>
      (
        <Stack
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
          <Typography variant="body2">
            {day.format('D')}
          </Typography>
          <Typography variant="body2">
            {day.format('dd')}
          </Typography>
        </Stack>
      ),
      cellClassName: (params) => {
        const isWeekend = params.value[1]
        const isPublicHoliday = params.value[2]
        const timeOff = params.value[3] as TimeOff
        if (isWeekend) {
          return clsx('weekend')
        }
        if (isPublicHoliday) {
          return clsx('publicHolidayCell')
        }
        if (timeOff && timeOff.status === TimeOffStatus.approved) {
          switch (timeOff.type) {
            case TimeOffType.PTO:
              return clsx('ptoDaysCell')
            case TimeOffType.Autorecenzare:
              return clsx('censusDaysCell')
            case TimeOffType.Marriage:
              return clsx('marriageDaysCell')
            case TimeOffType.BloodDonation:
              return clsx('bloodDaysCell')
            case TimeOffType.MaternityLeave:
              return clsx('maternityDaysCell')
            case TimeOffType.BereavementLeave:
              return clsx('bereavementDaysCell')
            case TimeOffType.Birthday:
              return clsx('birthdayDaysCell')
            case TimeOffType.PaternityLeave:
              return clsx('paternityDaysCell')
            case TimeOffType.MedicalLeave:
              return clsx('medDaysCell')
            case TimeOffType.CovidMedicalLeave:
              return clsx('covidMedDaysCell')
            case TimeOffType.StudyLeave:
              return clsx('studyDaysCell')
            case TimeOffType.Zlp:
              return clsx('zlpDaysCell')
            case TimeOffType.Adoption:
              return clsx('adoptionDaysCell')
            default:
              return clsx('validatedDayCell')
          }
        }

        return clsx('')
      },
      renderCell: (params) => {
        const publicHoliday = params.value[2] as PublicHoliday
        const timeOff = params.value[3] as TimeOff
        const dayTimesheet = params.value[5] as Timesheet
        const validated = params.value[4]
        const workedHours = params.value[0]
        return (
          <Tooltip title={timeOff?.type ?? publicHoliday?.name ?? ''}>
            <Grid container justifyContent="center" sx={{
              ...(validated && {
                border: 1,
                borderColor: theme => theme.palette.primary.main,
                borderRadius: 1
              }),
              ...(dayTimesheet && !validated && {
                border: 1,
                borderColor: customColors.error,
                borderRadius: 1
              })
            }}
            >
              <Grid item>
                <Typography variant="body2">
                  {workedHours}
                </Typography>
              </Grid>
            </Grid>
          </Tooltip>

        )
      }
    })
  })

  columns.push({
    field: 'totalDays',
    width: 60,
    headerClassName: 'totalDaysHeader',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    renderHeader: () =>
    (
      <CellHeader text="Total" subtext={'days'} />
    ),
    cellClassName: 'totalDaysCell'
  })

  columns.push({
    field: 'workDays',
    width: 60,
    headerClassName: 'workDaysHeader',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    renderHeader: () =>
    (
      <CellHeader text="Work" subtext={'days'} />
    ),
    cellClassName: 'workDaysCell'
  })

  columns.push({
    field: 'pto',
    width: 50,
    headerClassName: 'ptoDaysHeader',
    sortable: false,
    filterable: false,
    align: 'center',
    cellClassName: 'ptoDaysCell',
    renderHeader: () =>
    (
      <CellHeader text="PTO" />
    )
  })

  columns.push({
    field: 'med',
    width: 55,
    headerClassName: 'medDaysHeader',
    sortable: false,
    filterable: false,
    align: 'center',
    cellClassName: 'medDaysCell',
    renderHeader: () =>
    (
      <CellHeader text="Med" />
    )

  })

  columns.push({
    field: 'covidMed',
    width: 65,
    headerClassName: 'covidMedDaysHeader',
    sortable: false,
    filterable: false,
    align: 'center',
    cellClassName: 'covidMedDaysCell',
    renderHeader: () =>
    (
      <CellHeader text="Covid" />
    )

  })

  columns.push({
    field: 'mat',
    width: 55,
    headerClassName: 'maternityDaysHeader',
    sortable: false,
    filterable: false,
    align: 'center',
    cellClassName: 'maternityDaysCell',
    renderHeader: () =>
    (
      <CellHeader text="Mat" />
    )
  })

  columns.push({
    field: 'pat',
    width: 50,
    headerClassName: 'paternityDaysHeader',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    align: 'center',
    cellClassName: 'paternityDaysCell',
    renderHeader: () =>
    (
      <CellHeader text="Pat" />
    )

  })

  columns.push({
    field: 'census',
    width: 80,
    headerClassName: 'censusDaysHeader',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    align: 'center',
    cellClassName: 'censusDaysCell',
    renderHeader: () =>
    (
      <CellHeader text="Census" />
    )

  })

  columns.push({
    field: 'blood',
    headerName: 'Blood',
    width: 65,
    headerClassName: 'bloodDaysHeader',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    align: 'center',
    cellClassName: 'bloodDaysCell'
  })

  columns.push({
    field: 'study',
    headerName: 'Study',
    width: 65,
    headerClassName: 'studyDaysHeader',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    align: 'center',
    cellClassName: 'studyDaysCell'
  })

  columns.push({
    field: 'birthday',
    headerName: 'Bday',
    width: 65,
    headerClassName: 'birthdayDaysHeader',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    align: 'center',
    cellClassName: 'birthdayDaysCell'
  })

  columns.push({
    field: 'marriage',
    headerName: 'Marr.',
    width: 65,
    headerClassName: 'marriageDaysHeader',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    align: 'center',
    cellClassName: 'marriageDaysCell'
  })
  columns.push({
    field: 'bereavement',
    headerName: 'Brvm',
    width: 65,
    headerClassName: 'bereavementDaysHeader',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    align: 'center',
    cellClassName: 'bereavementDaysCell'
  })

  columns.push({
    field: 'zlp',
    headerName: 'Zlp',
    width: 65,
    headerClassName: 'zlpDaysHeader',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    align: 'center',
    cellClassName: 'zlpDaysCell'
  })

  columns.push({
    field: 'adoption',
    headerName: 'Adoption',
    width: 65,
    headerClassName: 'adoptionDaysHeader',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    align: 'center',
    cellClassName: 'adoptionDaysCell'
  })

  return columns
}
