import { useEffect, useState } from 'react'
import { defaultErrorHandler } from '../util/errorHandler'
import { useToast } from './use-toast'
import { TimeOff } from '../model/TimeOff'
import { timeOffService } from '../service/timeOffService'

export const useTimeOffs = (
  employeeId?: string):
{
  timeOffs: TimeOff[]
  loading: boolean
} => {
  const [timeOffs, setTimeOffs] = useState<TimeOff[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const toast = useToast()

  useEffect(() => {
    const fetchValidations = async () => {
      try {
        if (employeeId) {
          setLoading(true)
          const timeOffs = await timeOffService.fetchEmployeeTimeOffs(employeeId)
          setTimeOffs(timeOffs)
        }
      } catch (error) {
        defaultErrorHandler(error, toast)
      } finally {
        setLoading(false)
      }
    }

    fetchValidations()
  }, [employeeId])

  return {
    timeOffs,
    loading
  }
}
