export function defaultErrorHandler (error: unknown, toast: { show: (severity: ('success' | 'info' | 'warning' | 'error'), title: string, subtitle?: string) => void }) {
  console.error(error)
  if (error instanceof Error) {
    toast.show('error', error.message)
  } else if (typeof error === 'string') {
    toast.show('error', error)
  } else {
    toast.show('error', 'An error occurred')
    console.log(error)
  }
}
