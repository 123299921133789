import { useEffect, useState } from 'react'
import { defaultErrorHandler } from '../util/errorHandler'
import { useToast } from './use-toast'
import { timesheetService } from '../service/timesheetService'
import dayjs from 'dayjs'

export const useYearMonths = ():
{
  yearMonths: Record<string, Date[]> | undefined
  lastMonth: Date | undefined
} => {
  const [monthsOfYears, setMonthsOfYears] = useState<Record<string, Date[]>>()
  const [lastMonth, setLastMonth] = useState<Date>()
  const [maxValidation, setMaxValidation] = useState<Date>()
  const toast = useToast()
  const start = dayjs(new Date(2023, 1, 1)).startOf('year')

  useEffect(() => {
    timesheetService.retrieveLatestValidation()
      .then(val => {
        setMaxValidation(val.date)
      })
      .catch(err => {
        defaultErrorHandler(err, toast)
      })
  }, [])

  useEffect(() => {
    if (maxValidation) {
      const monthsOfYears: Record<string, Date[]> = {}
      const maxValidationMoment = dayjs(maxValidation)
      // putting years in the map
      let monthIterator = start
      while (monthIterator.isBefore(maxValidationMoment)) {
        if (!monthsOfYears[monthIterator.format('YYYY')]) {
          monthsOfYears[monthIterator.format('YYYY')] = []
        }
        monthsOfYears[monthIterator.format('YYYY')].push(monthIterator.clone().toDate())
        monthIterator = monthIterator.add(1, 'month')
      }

      setLastMonth(maxValidationMoment.toDate())
      setMonthsOfYears(monthsOfYears)
    }
  }, [maxValidation])

  return {
    yearMonths: monthsOfYears,
    lastMonth
  }
}
