import { FirestoreDataConverter, QueryDocumentSnapshot } from 'firebase/firestore'
import { DocumentData, Timestamp } from '@firebase/firestore'

export interface PublicHoliday {
  id: string
  date: Date
  name: string
}

export function fromObjectToPublicHoliday (data: DocumentData): PublicHoliday {
  return {
    id: data?.id,
    date: data?.date?.toDate(),
    name: data?.name
  }
}

export const publicHolidayConverter:
FirestoreDataConverter<PublicHoliday> = {
  toFirestore: (publicHoliday: PublicHoliday):
  DocumentData => {
    return {
      id: publicHoliday.id || null,
      date: Timestamp.fromDate(publicHoliday.date),
      name: publicHoliday.name || null
    }
  },
  fromFirestore (snapshot: QueryDocumentSnapshot): PublicHoliday {
    return fromObjectToPublicHoliday(snapshot.data())
  }
}
