import { createTheme, ThemeOptions } from '@mui/material/styles'
import { PaletteMode } from '@mui/material'
import { lightTheme } from './theme/lightTheme'
import { darkTheme } from './theme/darkTheme'

const defaultTheme = createTheme()

export const customColors = {
  primary: '#1867F7',
  style3: '#E2E8F6',
  white: '#F8F8F8',
  style2: '#0E1345',
  grey1: '#D9D9D9',
  weekend: '#F5F8FF',
  adminWeekend: '#ECECEC',
  grey2: '#A5A5A5',
  info: '#FCD50D',
  medicalLeave: '#FADB60',
  covidMedicalLeave: '#33FFFF',
  accent: '#7F04DC',
  accentBg: 'rgba(127, 4, 220, 0.1)',
  census: '#F6CA94',
  blood: '#ED672C',
  publicHolidays: '#25AC44',
  study: '#2C2D8E',
  birthDay: '#F09898',
  paternity: '#9BBDFB',
  validated: '#F6FFF5',
  validatedCalendar: '#C7EEC3',
  error2: '#FBBFBF',
  error: '#D10A0A',
  pto: '#123B86',
  bereavement: '#000000',
  marriage: '#e91e63',
  maternity: '#CC9CE2',
  success: '#216036',
  calendarDarkBackground: '#121212',
  zlp: '#D9D9D9',
  adoption: '#0E1345'
}

export function getDesignTokens(colorMode: PaletteMode): ThemeOptions {
  switch (colorMode) {
    case 'light':
      return lightTheme(defaultTheme)
    case 'dark':
      return darkTheme(defaultTheme)
  }
}

// A custom theme for this app
export const theme = createTheme(getDesignTokens('light'))
