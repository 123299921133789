import React, { useEffect, useState } from 'react'
import { CircularProgress, PaletteMode, ThemeProvider, useMediaQuery } from '@mui/material'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { auth } from './config/firebase'
import routes from './config/routes'
import Center from './components/utils/Center'
import AuthChecker from './components/auth/AuthChecker'
import { Page } from './components/utils/Page'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { UserContext } from './context/UserContext'
import { User } from 'firebase/auth'
import { AppContext, AppContextParams } from './context/AppContext'
import { useEmployeeId } from './hooks/useEmployeeId'
import { employeeService } from './service/employeeService'
import { useToast } from './hooks/use-toast'
import { defaultErrorHandler } from './util/errorHandler'
import { adminService } from './service/adminService'
import { createTheme } from '@mui/material/styles'
import { getDesignTokens } from './theme'
import CssBaseline from '@mui/material/CssBaseline'

import dayjs from 'dayjs'
import 'dayjs/locale/en-gb'

dayjs.locale('en-gb')

export const ColorModeContext = React.createContext<{ toggleColorMode: () => void }>({
  toggleColorMode: () => {
  }
})

function App () {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<User | null>(null)

  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [mode
    // setMode
  ] = React.useState<PaletteMode>('light')
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode])

  const lessThanMid = useMediaQuery(theme.breakpoints.down('md'))
  const employeeId = useEmployeeId(user)
  const [prefs, setPrefs] = useState<AppContextParams>({
    drawerOpen: !lessThanMid
  })
  const toast = useToast()

  // const colorMode = React.useMemo(
  //   () => ({
  //     // The dark mode switch would invoke this method
  //     toggleColorMode: () => {
  //       setMode((prevMode: PaletteMode) =>
  //         prevMode === 'light' ? 'dark' : 'light'
  //       )
  //     }
  //   }),
  //   []
  // )

  // Update the theme only if the mode changes

  useEffect(() => {
    setPrefs((prev) => ({ ...prev, employeeId }))

    const retrieveEmployee = async () => {
      try {
        if (employeeId) {
          const employee = await employeeService.getEmployee(employeeId)
          setPrefs((prev) => ({ ...prev, employee }))
        }
      } catch (e) {
        defaultErrorHandler(e, toast)
      }
    }

    retrieveEmployee()
  }, [employeeId])

  useEffect(() => {
    const isAdminFc = async (userId: string) => {
      if (userId) {
        const isAdmin = await adminService.isAdmin(userId)
        setPrefs((prev) => ({ ...prev, isAdmin }))
      }
    }

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        isAdminFc(user.uid).then(() => {
          setUser(user)
        })
      } else {
        console.info('No user detected')
        setPrefs((prev) => ({ ...prev, drawerOpen: prefs.drawerOpen }))
        setUser(null)
      }
      setLoading(false)

      return () => {
        unsubscribe()
      }
    })
  }, [])

  if (loading) {
    return (
        <Center>
          <CircularProgress/>
        </Center>
    )
  }

  return (
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {/* <ColorModeContext.Provider value={'light'}> */}
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
              {routes.map((route, index) => (
                  <Route
                      key={index}
                      path={route.path}
                      element={
                        route.protected
                          ? (
                                <AuthChecker>
                                  <Page title={route.name}>
                                    <UserContext.Provider value={user}>
                                      <AppContext.Provider
                                          value={{
                                            appPrefs: prefs,
                                            updatePrefs: newPrefs => {
                                              setPrefs(newPrefs)
                                            }
                                          }}>
                                        <route.component/>
                                      </AppContext.Provider>
                                    </UserContext.Provider>
                                  </Page>
                                </AuthChecker>
                            )
                          : (
                                <Page title={route.name}>
                                  <route.component/>
                                </Page>
                            )
                      }
                  />
              ))}
              <Route path="*" element={prefs.isAdmin ? <Navigate to={'/dashboard'}/> : <Navigate to={'/timesheet'}/>}/>
            </Routes>
          </BrowserRouter>
          <ToastContainer position="bottom-center"
                          autoClose={5000}
                          hideProgressBar
                          pauseOnHover
                          transition={Slide}/>
        {/* </ColorModeContext.Provider> */}
      </ThemeProvider>
  )
}

export default App
