import dayjs from 'dayjs'
import { Timesheet } from '../../model/Timesheet'
import { TimeOff, TimeOffStatus, TimeOffType } from '../../model/TimeOff'
import { PublicHoliday } from '../../model/PublicHoliday'
import { customColors } from '../../theme'
import { Employee, EmployeeStatus } from '../../model/Employee'
import { isWeekend } from '../../util/dateUtils'

export function findTimesheet(date: dayjs.Dayjs, timesheet: Timesheet[]): Timesheet | undefined {
  return timesheet.find((timesheet) => {
    return dayjs(timesheet.date).isSame(date, 'day')
  })
}

export function findTimeOff(date: dayjs.Dayjs, timeOffs: TimeOff[]) {
  return timeOffs.find((timeOff) => {
    return dayjs(timeOff.date).isSame(date, 'day')
  })
}

export function findPublicHoliday(publicHolidays: PublicHoliday[], date: dayjs.Dayjs) {
  return publicHolidays.find((publicHoliday) => {
    return dayjs(publicHoliday.date).isSame(date, 'day')
  })
}

export function getTimeOffColor(timeOff: TimeOff): string {
  if (timeOff.status === TimeOffStatus.superceded) return customColors.info
  if (timeOff.status !== TimeOffStatus.approved) return customColors.error

  switch (timeOff.type) {
    case TimeOffType.PTO:
      return customColors.pto
    case TimeOffType.MedicalLeave:
      return customColors.medicalLeave
    case TimeOffType.CovidMedicalLeave:
      return customColors.covidMedicalLeave
    case TimeOffType.Birthday:
      return customColors.birthDay
    case TimeOffType.Autorecenzare:
      return customColors.census
    case TimeOffType.BereavementLeave:
      return customColors.bereavement
    case TimeOffType.BloodDonation:
      return customColors.blood
    case TimeOffType.Marriage:
      return customColors.marriage
    case TimeOffType.MaternityLeave:
      return customColors.maternity
    case TimeOffType.PaternityLeave:
      return customColors.paternity
    case TimeOffType.StudyLeave:
      return customColors.study
    case TimeOffType.Zlp:
      return customColors.zlp
    case TimeOffType.Adoption:
      return customColors.adoption
  }
}

export function getTooltipTitle(
  publicHoliday?: PublicHoliday,
  dayIsValidated?: boolean,
  isWeekend?: boolean,
  timeOff?: TimeOff) {
  if (publicHoliday) {
    return publicHoliday.name
  }

  if (isWeekend) {
    return 'Weekend'
  }

  if (timeOff) {
    return `${timeOff.type} ${timeOff.status}`
  }

  if (dayIsValidated) {
    return 'Validated working day'
  } else {
    return 'Working day'
  }
}

export function computeDayColor(
  date: dayjs.Dayjs,
  timesheet: Timesheet,
  timeOffs: TimeOff[],
  publicHolidays: PublicHoliday[]): string {
  const publicHoliday = publicHolidays.find((publicHoliday) => {
    return dayjs(publicHoliday.date).isSame(date, 'day')
  })
  if (publicHoliday) {
    return customColors.publicHolidays
  }
  if (isWeekend(date)) {
    return customColors.grey1
  }

  const timeOff = findTimeOff(date, timeOffs)
  if (timeOff) {
    return getTimeOffColor(timeOff)
  }

  return '#FFFFFF'
}

export function computeText(
  date: dayjs.Dayjs,
  timesheet: Timesheet,
  timeOffs: TimeOff[],
  publicHolidays: PublicHoliday[],
  employee?: Employee): string {
  if (isWeekend(date)) {
    return '-'
  }

  const publicHoliday = findPublicHoliday(publicHolidays, date)
  if (publicHoliday) {
    return publicHoliday.name
  }

  const timeOff = findTimeOff(date, timeOffs)
  if (timeOff) {
    return timeOff.type
  }
  if (employee?.employmentHistoryStatus === EmployeeStatus.PartTime) {
    return '09:00 - 14:00'
  }

  return '09:00 - 18:00'
}
