import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../config/firebase'

const AuthChecker: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/login')
    }
  }, [])

  return <>{children}</>
}

export default AuthChecker
