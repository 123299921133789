import { useEffect, useState } from 'react'
import { defaultErrorHandler } from '../util/errorHandler'
import { useToast } from './use-toast'
import { usePrevious } from './use-previous'
import dayjs from 'dayjs'
import { Timesheet } from '../model/Timesheet'
import { timesheetService } from '../service/timesheetService'

export function groupTimesheetByEmployeeId (timesheet: Timesheet[]) {
  const employeeTimeOffs: Record<string, Timesheet[]> = {}
  timesheet.forEach((t) => {
    if (!employeeTimeOffs[t.employeeId]) {
      employeeTimeOffs[t.employeeId] = []
    }
    employeeTimeOffs[t.employeeId].push(t)
  })
  return employeeTimeOffs
}

export const useAdminTimesheet = (
  employeeIds: string[],
  startDate: Date,
  endDate: Date
):
  {
    timesheet: Record<string, Timesheet[]>
    loading: boolean
    refetch: () => void
  } => {
  const [timesheet, setTimesheet] = useState<Record<string, Timesheet[]>>({})
  const previousEmployeeIds = usePrevious(employeeIds)
  const previousStartDate = usePrevious(startDate)
  const previousEndDate = usePrevious(endDate)

  const [loading, setLoading] = useState<boolean>(false)
  const toast = useToast()

  function fetchTimesheet () {
    setLoading(true)
    timesheetService
      .retrieveTimeSheetForEmployees(employeeIds, startDate, endDate)
      .then((timesheet) => { setTimesheet(groupTimesheetByEmployeeId(timesheet)) })
      .catch((err) => {
        defaultErrorHandler(err, toast)
      }).finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (previousEmployeeIds &&
        previousEmployeeIds.length === employeeIds.length &&
        previousEmployeeIds.every(
          (id, index) => id === employeeIds[index]
        )) {
      const previousStartDateMoment = dayjs(previousStartDate)
      const previousEndDateMoment = dayjs(previousEndDate)
      if (previousStartDateMoment.isSame(startDate, 'day') && previousEndDateMoment.isSame(endDate, 'day')) {
        // no work to do
        return
      }
    }

    if (employeeIds?.length === 0 || !startDate || !endDate) {
      return
    }

    fetchTimesheet()
  }, [employeeIds, startDate, endDate])

  return {
    timesheet,
    loading,
    refetch: fetchTimesheet
  }
}
