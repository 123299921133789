import React, { useContext } from 'react'
import {
  AppBarProps,
  Avatar,
  Box,
  Button,
  createTheme,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  styled,
  ThemeProvider,
  Toolbar,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import { Logo } from '../utils/Logo'
import { UserContext } from '../../context/UserContext'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { customColors } from '../../theme'
import { AClockDrawer, DrawerHeader, drawerWidth } from './AClockDrawer'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import { auth } from '../../config/firebase'
import { signOut } from 'firebase/auth'
// import Brightness4Icon from '@mui/icons-material/Brightness4'
// import Brightness7Icon from '@mui/icons-material/Brightness7'
// import { ColorModeContext } from '../../App'

const AClockAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps & { open: boolean }>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

export const AppBar = () => {
  const user = useContext(UserContext)
  const { appPrefs, updatePrefs } = useContext(AppContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const signOutClicked = () => {
    setAnchorEl(null)
    signOut(auth)
      .then(() => {
        navigate('/login')
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleDrawerOpen = () => {
    updatePrefs({ ...appPrefs, drawerOpen: true })
  }

  const handleDrawerClose = () => {
    updatePrefs({ ...appPrefs, drawerOpen: false })
  }

  const open = !!appPrefs.drawerOpen

  const drawerItems = [
    {
      text: 'Timesheet',
      path: '/timesheet',
      icon: <ContentPasteIcon/>
    }
  ]

  if (appPrefs.isAdmin) {
    drawerItems.unshift({
      text: 'Dashboard',
      path: '/dashboard',
      icon: <DashboardIcon/>
    })
  }

  const MobileNav = () => {
    return (
      <Box sx={{
        display: 'block',
        position: 'fixed',
        top: 0,
        zIndex: 1,
        width: '100vw',
        height: '75px',
        backgroundColor: customColors.white
      }}>
      <Box sx={{
        top: '20px',
        position: 'fixed',
        transform: 'translateX(-50%)',
        left: '50%',
        zIndex: 2
      }}>
        <Logo size="small"/>
      </Box>
      </Box>
    )
  }
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const showDrawer = drawerItems.length > 1
  // const colorMode = useContext(ColorModeContext)

  if (isMobile) {
    return <MobileNav/>
  }
  return (
      <>
        <AClockAppBar position="fixed"
                      open={open}
                      sx={{ width: '100%' }}
                      color="default"
                      elevation={0}>
          <Toolbar color="secondary">
            <Logo size="small"/>
            {user && (
                <Stack
                    direction="row"
                    flexGrow={1}
                    display="flex"
                    alignItems="center"
                    justifyItems="flex-end"
                    justifyContent="flex-end">
                  <Box component="span">
                    {/* <IconButton onClick={colorMode.toggleColorMode}> */}
                    {/*  {theme.palette.mode === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/>} */}
                    {/* </IconButton> */}
                  </Box>
                  <Tooltip title={user.displayName}>
                    <IconButton onClick={handleMenu}>
                      {
                        user.photoURL
                          ? (
                                <Avatar src={user.photoURL} alt={user.displayName ?? ''}/>)
                          : (
                                <Avatar>{user.displayName?.charAt(0)}</Avatar>)
                      }
                    </IconButton>
                  </Tooltip>

                  <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      open={Boolean(anchorEl)}
                      onClose={() => {
                        setAnchorEl(null)
                      }}
                  >
                    <MenuItem onClick={signOutClicked}>Logout</MenuItem>
                  </Menu>
                </Stack>
            )}
          </Toolbar>
        </AClockAppBar>
        {showDrawer && <AClockDrawer variant="permanent" open={open}>
          <DrawerHeader/>
          <Stack direction="row" mt={4} mr={open ? 2 : 0}
                 justifyContent={open ? 'flex-end' : 'center'}>
            <ThemeProvider theme={createTheme({ palette: { primary: { main: customColors.style3 } } })}>
              {
                open
                  ? (
                        <Button onClick={handleDrawerClose} color="primary" variant="contained" sx={
                          {
                            borderRadius: '50%',
                            padding: 1.5,
                            minWidth: 'initial'
                          }}>
                          <ChevronLeftIcon fontSize="large"/>
                        </Button>
                    )
                  : (
                        <Button onClick={handleDrawerOpen} color="primary" variant="contained" sx={{
                          borderRadius: '50%',
                          padding: 1.5,
                          minWidth: 'initial'
                        }}>
                          <ChevronRightIcon fontSize="large"/>
                        </Button>
                    )
              }
            </ThemeProvider>
          </Stack>
          <List sx={{ margin: 1 }}>
            {
              drawerItems.map((item) => {
                const selected = item.path === location.pathname
                return (
                    <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
                      <ListItemButton selected={selected} sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5
                      }} color="primary" onClick={() => {
                        !selected && navigate(item.path)
                      }}>
                        <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                              color: theme => selected ? theme.palette.primary.contrastText : 'inherit'
                            }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }}/>
                      </ListItemButton>
                    </ListItem>
                )
              })
            }
          </List>
        </AClockDrawer>}
      </>
  )
}
