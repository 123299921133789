import React, { useContext } from 'react'
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { customColors } from '../theme'
import { AppBar } from '../components/appBar/AppBar'
import { DrawerHeader } from '../components/appBar/AClockDrawer'
import { UserContext } from '../context/UserContext'
import { AppContext } from '../context/AppContext'
import { useTimeOffs } from '../hooks/use-time-offs'
import { usePublicHolidays } from '../hooks/use-public-holidays'
import { useEmployeeTimesheet } from '../hooks/use-employee-timesheet'
import { EmployeeTimesheet } from '../components/employee/EmployeeTimesheet'

const TimeSheet = () => {
  const user = useContext(UserContext)
  const { appPrefs: { employee } } = useContext(AppContext)
  const { loading: loadingTimeOffs, timeOffs } = useTimeOffs(employee?.id)
  const { loading: loadingPublicHolidays, publicHolidays } = usePublicHolidays()
  const { timesheet } = useEmployeeTimesheet(employee?.id)

  const isLoading = !employee || loadingPublicHolidays || loadingTimeOffs
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
      <Box sx={{ display: 'flex', flexGrow: 1, paddingTop: isMobile ? '10px' : null, backgroundColor: theme => theme.palette.background.default }}>
        <AppBar/>
        <Box component="main" sx={{
          flexGrow: 1
        }}>
          <DrawerHeader/>
          <Stack sx={{
            backgroundColor: theme => theme.palette.background.default,
            height: '100%',
            borderColor: theme => theme.palette.mode === 'light'
              ? `${customColors.style2}`
              : theme.palette.getContrastText(customColors.style2),
            borderStyle: isMobile ? 'none' : 'solid',
            borderWidth: '1px 0px 0px 1px',
            borderRadius: isMobile ? null : '32px 0px 0px 0px'
          }} p={isMobile ? 2 : 4} spacing={isMobile ? 0 : 1}>
            <Typography variant="h4" fontWeight="bold">{employee?.displayName ?? user?.displayName}</Typography>
            <Typography variant="body2">{employee?.employmentHistoryStatus?.toString()}</Typography>
            <EmployeeTimesheet
                loading={isLoading}
                timesheet={timesheet}
                timeOffs={timeOffs}
                employee={employee}
                publicHolidays={publicHolidays}/>
          </Stack>
        </Box>
      </Box>
  )
}
export default TimeSheet
