import React from 'react'
import { Box, Grid, Stack, useMediaQuery, useTheme } from '@mui/material'
import AuthContainer from '../components/auth/AuthContainer'
import { Logo } from '../components/utils/Logo'
import LoginImage from '../assets/images/login-image.svg'
import { customColors } from '../theme'

const Login = () => {
  const theme = useTheme()
  const lessThanMid = useMediaQuery(theme.breakpoints.down('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (lessThanMid) {
    return (
      <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              backgroundColor: lessThanMid ? customColors.style2 : null,
              color: lessThanMid ? customColors.white : 'black',
              paddingTop: lessThanMid ? 10 : 0
            }}>
          <Stack justifyContent="center"
                 alignItems="center"
                 spacing={3}>
            <Box>
              <Logo size={isMobile ? 'small' : 'medium'} color={customColors.style3}/>
            </Box>
            <img src={LoginImage} width={isMobile ? 200 : 270} alt="login-image"/>
          </Stack>
        <Grid item container xs={7} md={7} justifyContent="center" sx={{ marginTop: lessThanMid ? 10 : 0 }}>
          <Grid item>
            <AuthContainer/>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
      <Grid direction={lessThanMid ? 'column' : 'row'} container justifyContent="center" alignItems="center"
            sx={{
              height: '100vh',
              backgroundColor: lessThanMid ? customColors.style2 : null,
              color: lessThanMid ? customColors.white : 'black',
              paddingTop: lessThanMid ? 10 : 0
            }}>
        {!lessThanMid && <Grid item xs={12} md={5}>
          <Stack height={'100vh'} justifyContent="center"
                 alignItems="center"
                 spacing={20}
                 sx={{
                   backgroundColor: customColors.style2,
                   borderTopRightRadius: 40,
                   borderBottomRightRadius: 40
                 }}>
            <Box>
              <Logo color={customColors.style3}/>
            </Box>
            <img src={LoginImage} alt="login-image"/>
          </Stack>
        </Grid>
        }
        <Grid item container xs={7} md={7} justifyContent="center" sx={{ marginTop: lessThanMid ? 10 : 0 }}>
          <Grid item>
            <AuthContainer/>
          </Grid>
        </Grid>
      </Grid>
  )
}

export default Login
