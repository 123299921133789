import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  startAfter,
  where,
  getCountFromServer
} from 'firebase/firestore'
import { db } from '../config/firebase'
import { Employee, employeesConverter, EmployeeStatus } from '../model/Employee'

export const employeeService = {
  getActiveEmployees: async (contractType?: EmployeeStatus | null, divisionName?: string | null, cursor?: any, pageSize?: number): Promise<{ employees: Employee[], nextPage: QueryDocumentSnapshot, count: number }> => {
    return await new Promise<{ employees: Employee[], nextPage: QueryDocumentSnapshot, count: number }>((resolve, reject) => {
      const getActiveEmployees = async () => {
        const employeesCollection = collection(db, 'employees').withConverter(employeesConverter)
        const queryConstraints: QueryConstraint[] = [
          orderBy('lastName')
        ]
        if (contractType) {
          queryConstraints.push(where('employmentHistoryStatus', '==', contractType.toString()))
        } else {
          queryConstraints.push(
            where('employmentHistoryStatus', 'in', [EmployeeStatus.FullTime.toString(), EmployeeStatus.PartTime.toString(), EmployeeStatus.Terminated.toString(), EmployeeStatus.Contractor.toString()])
          )
        }

        // Add divisionName filter if provided
        if (divisionName && divisionName.length > 0) {
          queryConstraints.push(where('division', '==', divisionName))
        }

        const countRes = await getCountFromServer(query(employeesCollection, ...queryConstraints))
        if (cursor) {
          queryConstraints.push(startAfter(cursor))
        }
        if (pageSize) {
          queryConstraints.push(limit(pageSize))
        }

        const q = query(employeesCollection,
          ...queryConstraints
        )

        getDocs(q).then((querySnapshot) => {
          const employees: Employee[] = []
          querySnapshot.forEach((doc) => {
            employees.push(doc.data())
          })
          resolve({
            employees,
            nextPage: querySnapshot.docs[querySnapshot.docs.length - 1],
            count: countRes.data().count
          })
        }).catch((error) => {
          reject(error)
        })
      }

      getActiveEmployees()
    })
  },
  getEmployee: async (employeeId: string): Promise<Employee> => {
    return await new Promise<Employee>((resolve, reject) => {
      const getEmployee = async () => {
        if (!employeeId) {
          reject(new Error('employeeId is required'))
        }

        try {
          const employeeSnap = await getDoc(
            doc(
              collection(db, 'employees'),
              employeeId
            ).withConverter(employeesConverter))

          if (employeeSnap.exists()) {
            resolve(employeeSnap.data())
          } else {
            reject(new Error('Employee not found'))
          }
        } catch (error) {
          reject(error)
        }
      }

      getEmployee()
    })
  },

  getEmployeeCount: async (): Promise<number> =>
    await new Promise<number>((resolve, reject) => {
      const employeesCollection = collection(db, 'employees')
      getCountFromServer(employeesCollection)
        .then((count) => {
          resolve(count.data().count)
        }).catch((error) => {
          reject(error)
        })
    }),

  getUniqueDivisions: async (): Promise<string[]> => {
    return await new Promise<string[]>((resolve, reject) => {
      const fetchDivisions = async () => {
        const employeesCollection = collection(db, 'employees').withConverter(employeesConverter)
        const divisionsSet = new Set<string>()

        getDocs(employeesCollection).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const employee = doc.data()
            if (employee.division) {
              divisionsSet.add(employee.division)
            }
          })
          resolve(Array.from(divisionsSet))
        }).catch((error) => {
          reject(error)
        })
      }

      fetchDivisions()
    })
  }

}
