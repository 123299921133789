import { Timesheet } from '../model/Timesheet'
import { useEffect, useState } from 'react'
import { defaultErrorHandler } from '../util/errorHandler'
import { useToast } from './use-toast'
import { timesheetService } from '../service/timesheetService'

export const useEmployeeTimesheet = (
  employeeId?: string
):
{
  timesheet: Timesheet[]
} => {
  const [timesheet, setTimesheet] = useState<Timesheet[]>([])
  const toast = useToast()

  useEffect(() => {
    let unsubscribe: () => void = () => {
    }
    if (employeeId) {
      unsubscribe = timesheetService.onValidationsChanged(
        employeeId,
        setTimesheet,
        (err) => {
          setTimesheet([])
          defaultErrorHandler(err, toast)
        })
    }
    return () => {
      unsubscribe()
    }
  }, [employeeId])

  return {
    timesheet
  }
}
