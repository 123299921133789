import Typography from '@mui/material/Typography'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Paper, useMediaQuery, useTheme } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import dayjs from 'dayjs'
import { computeDayColor, computeText } from './employeeCalendarUtils'
import React from 'react'
import { Timesheet } from '../../model/Timesheet'
import { TimeOff } from '../../model/TimeOff'
import { PublicHoliday } from '../../model/PublicHoliday'
import { Employee } from '../../model/Employee'

export const EmployeeListView: React.FC<{
  timesheet: Timesheet[]
  timeOffs: TimeOff[]
  publicHolidays: PublicHoliday[]
  employee?: Employee
}> = ({ timesheet, timeOffs, publicHolidays, employee }) => {
  const theme = useTheme()
  const timesheetGroupedByMonth = timesheet?.reduce<Record<string, Timesheet[]>>((acc, timesheet) => {
    const month = dayjs(timesheet.date).format('YYYY-MM')
    if (!acc[month]) {
      acc[month] = []
    }
    acc[month].push(timesheet)
    return acc
  }, {}) ?? {}

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
    <Typography color="primary" variant="h4" fontWeight="700" sx={{ marginBottom: 2 }}>LIST VIEW</Typography>
        <Paper sx={{
          padding: 2,
          backgroundColor: theme => theme.palette.background.paper,
          borderRadius: '10.175px',
          width: isMobile ? '90vw' : '420px',
          maxHeight: 800,
          overflow: 'auto'
        }}
               elevation={1}>
            {
                timesheetGroupedByMonth && Object.keys(timesheetGroupedByMonth).map((month) => {
                  return (
                        <Accordion key={month} elevation={0} sx={{ backgroundColor: 'transparent' }} defaultExpanded={true}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>} id={`panel-${month}`} sx={{ marginY: -2, padding: 0 }}>
                                <Typography variant="body2" color="primary"
                                            fontWeight="700">{dayjs(month).format('MMMM')}</Typography>
                            </AccordionSummary>
                            {timesheetGroupedByMonth[month].map((timesheet) => {
                              const date = dayjs(timesheet.date)
                              const backgroundColor = computeDayColor(date, timesheet, timeOffs, publicHolidays)
                              const text = computeText(date, timesheet, timeOffs, publicHolidays, employee)
                              return (
                                    <AccordionDetails key={timesheet.id}
                                                      sx={{
                                                        paddingY: 0.5,
                                                        backgroundColor,
                                                        borderRadius: '8px',
                                                        marginTop: 0.5
                                                      }}>
                                        <Grid container direction="row" flexGrow={1} justifyContent="space-between"
                                              alignItems="center">
                                            <Grid item xs={2}>
                                                <Box component="span">
                                                    <Typography color={theme.palette.getContrastText(backgroundColor)}
                                                                textAlign="center"
                                                                variant="body2">{date.format('ddd')}</Typography>
                                                    <Typography variant="body1" textAlign="center" fontWeight="600"
                                                                color={theme.palette.getContrastText(backgroundColor)}>
                                                        {date.format('D')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography color={theme.palette.getContrastText(backgroundColor)}
                                                            variant="body2" textAlign="center">{text}</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                              )
                            })}
                        </Accordion>
                  )
                })
            }
        </Paper>
        </>
  )
}
