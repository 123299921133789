import { useEffect, useState } from 'react'
import { defaultErrorHandler } from '../util/errorHandler'
import { useToast } from './use-toast'
import { PublicHoliday } from '../model/PublicHoliday'
import { publicHolidayService } from '../service/publicHolidayService'

export const usePublicHolidays = ():
{
  publicHolidays: PublicHoliday[]
  loading: boolean
} => {
  const [publicHolidays, setPublicHolidays] = useState<PublicHoliday[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const toast = useToast()

  useEffect(() => {
    const fetchPublicHolidays = async () => {
      try {
        setLoading(true)
        const publicHolidays = await publicHolidayService.fetchPublicHolidays()
        setPublicHolidays(publicHolidays)
      } catch (error) {
        defaultErrorHandler(error, toast)
      } finally {
        setLoading(false)
      }
    }

    fetchPublicHolidays()
  }, [])

  return {
    publicHolidays,
    loading
  }
}
