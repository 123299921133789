import { ThemeOptions } from '@mui/material/styles'
import { customColors } from '../theme'
import { Theme } from '@mui/material'

export const darkTheme = (defaultTheme: Theme): ThemeOptions => ({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    h1: {
      fontWeight: 600,
      fontSize: defaultTheme.typography.pxToRem(64)
    },
    h2: {
      fontWeight: 500,
      fontSize: defaultTheme.typography.pxToRem(48)
    },
    h3: {
      fontWeight: 500,
      fontSize: defaultTheme.typography.pxToRem(32)
    },
    h4: {
      fontWeight: 500,
      fontSize: defaultTheme.typography.pxToRem(24)
    },
    body1: {
      fontWeight: 500,
      fontSize: defaultTheme.typography.pxToRem(20)
    },
    body2: {
      fontWeight: 400,
      fontSize: defaultTheme.typography.pxToRem(16)
    },
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: customColors.primary
    },
    secondary: {
      main: '#E2E8F6'
    },
    error: {
      main: '#D10A0A'
    },
    warning: {
      main: '#FCD50D'
    },
    success: {
      main: customColors.success
    },
    text: {
      primary: customColors.white
    },
    background: {
      default: '#000000'
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
        disableElevation: true
      },
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          marginLeft: 4,
          marginRight: 4,
          borderRadius: 8
        }
      },
      variants: [
        {
          props: { variant: 'outlined' },

          style: {
            border: '2px solid ' + customColors.primary,

            '&:hover': {
              border: '2px solid ' + customColors.primary
            }
          }
        }
      ]
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: 'none'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          '&.Mui-selected': {
            color: customColors.style3,
            backgroundColor: customColors.primary
          },
          '&.Mui-selected:hover': {
            color: customColors.style3,
            backgroundColor: customColors.primary
          }
        }
      }
    }
  }
})
