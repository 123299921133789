import { collection, getDocs, query } from 'firebase/firestore'
import { db } from '../config/firebase'
import { PublicHoliday, publicHolidayConverter } from '../model/PublicHoliday'

export const publicHolidayService = {
  fetchPublicHolidays: async (): Promise<PublicHoliday[]> => {
    return await new Promise<PublicHoliday[]>((resolve, reject) => {
      const fetchPublicHolidaysFc = async () => {
        const q = query(
          collection(db, 'publicHolidays').withConverter(publicHolidayConverter)
        )

        getDocs(q).then((querySnapshot) => {
          const publicHolidays: PublicHoliday[] = []
          querySnapshot.forEach((doc) => {
            publicHolidays.push(doc.data())
          })
          resolve(publicHolidays)
        }).catch((error) => {
          reject(error)
        })
      }

      fetchPublicHolidaysFc()
    })
  }
}
