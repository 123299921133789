import { createContext } from 'react'
import { Employee } from '../model/Employee'

export interface AppContextParams {
  drawerOpen?: boolean
  employeeId?: string | null
  employee?: Employee
  isAdmin?: boolean
}

export const AppContext = createContext<{ appPrefs: AppContextParams, updatePrefs: (appPrefs: AppContextParams) => void }>(
  {
    appPrefs: {},
    updatePrefs: (appPrefs: AppContextParams) => {
      // do nothing by default
    }
  }
)
