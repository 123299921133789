import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import React from 'react'

export const CellHeader: React.FC<{ text: string, subtext?: string }> = ({ text, subtext }) =>
  (
        <Grid container xs={12} justifyContent="center" alignItems="center" justifyItems="center">
            <Grid item>
                <Typography variant="body2">{text}</Typography>
            </Grid>
            {subtext && (
                <Grid item>
                    <Typography variant="body2">
                        {subtext}
                    </Typography>
                </Grid>
            )}
        </Grid>
  )
