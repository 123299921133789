import { collection, doc, getDoc } from 'firebase/firestore'
import { db } from '../config/firebase'
import { employeesConverter } from '../model/Employee'

export const adminService = {
  isAdmin: async (userId: string): Promise<boolean> => {
    return await new Promise<boolean>((resolve) => {
      const isAdminFc = async () => {
        if (!userId) {
          resolve(false)
        }

        try {
          const adminSnap = await getDoc(
            doc(
              collection(db, 'admins'),
              userId
            ).withConverter(employeesConverter))

          if (adminSnap.exists()) {
            resolve(true)
          } else {
            resolve(false)
          }
        } catch (error) {
          resolve(false)
        }
      }

      isAdminFc()
    })
  }
}
