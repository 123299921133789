import React from 'react'
import { toast } from 'react-toastify'
import { Stack, Theme } from '@mui/material'
import CheckIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Typography from '@mui/material/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

export function useToast () {
  return {
    show: (severity: 'success' | 'info' | 'warning' | 'error', title: string, subtitle?: string) => {
      toast(<Toast title={title} severity={severity} subtitle={subtitle}/>)
    },
    loading: (title: string, subtitle?: string) => {
      toast.loading(<Toast title={title} severity={'loading'} subtitle={subtitle}/>)
    },
    dismiss: () => {
      toast.dismiss()
    }
  }
}

const Toast = ({
  title,
  severity,
  subtitle
}: { severity: 'success' | 'info' | 'warning' | 'error' | 'loading', title: string, subtitle?: string }) => {
  return (
      <Stack direction="row" alignItems="center" justifyItems="center" spacing={2} alignContent="center">
        <Stack p={1} sx={{
          backgroundColor: theme => getBackgroundColorFromSeverity(severity, theme),
          borderRadius: '50%'
        }}>
          {severity === 'success' &&
              <CheckIcon sx={{ color: theme => theme.palette.success.contrastText }}
                         fontSize="large"/>}
          {severity === 'error' &&
              <ErrorOutlineIcon sx={{
                color: theme => theme.palette.error.contrastText
              }}
                                fontSize="large"/>}
          {severity === 'info' &&
              <InfoOutlinedIcon sx={{ color: theme => theme.palette.info.contrastText }}
                                fontSize="large"/>}
          {severity === 'warning' &&
              <WarningAmberOutlinedIcon sx={{ color: theme => theme.palette.warning.contrastText }}
                                        fontSize="large"/>}
        </Stack>
        <Stack spacing={1} alignItems="center" alignContent="center">
          <Typography variant="body1">
            {title}
          </Typography>
          {subtitle &&
              <Typography variant="body2">
            {subtitle}
          </Typography>
          }
        </Stack>
      </Stack>
  )
}

function getBackgroundColorFromSeverity (severity: 'success' | 'info' | 'warning' | 'error' | 'loading', theme: Theme) {
  switch (severity) {
    case 'success':
      return theme.palette.success.main
    case 'error':
      return theme.palette.error.main
    case 'warning':
      return theme.palette.warning.main
    case 'info':
      return theme.palette.info.main
    case 'loading':
      return theme.palette.background.paper
  }
}
