import React from 'react'
import { Box } from '@mui/material'
import { AppBar } from '../components/appBar/AppBar'
import { DrawerHeader } from '../components/appBar/AClockDrawer'
import { customColors } from '../theme'
import { EmployeeDailyHoursTable } from '../components/admin/EmployeeDailyHoursTable'

export const Dashboard = () => {
  return (
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <AppBar/>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <DrawerHeader/>
                <Box sx={{
                  borderStyle: 'solid',
                  borderColor: theme => theme.palette.mode === 'light'
                    ? `${customColors.style2}`
                    : theme.palette.getContrastText(customColors.style2),
                  borderWidth: '1px 0px 0px 1px',
                  borderRadius: '32px 0px 0px 0px'
                }} p={4}>
                    <EmployeeDailyHoursTable/>
                </Box>
            </Box>
        </Box>
  )
}
