import React from 'react'
import { alpha, Box, Stack, useTheme } from '@mui/material'
import { customColors } from '../../theme'
import { DataGrid } from '@mui/x-data-grid'
import { generateDailyHoursTableColumns } from '../../util/tableUtils'
import Typography from '@mui/material/Typography'
import { getDaysArrayByMonth, isWeekend } from '../../util/dateUtils'
import { useEmployees } from '../../hooks/use-employees'
import { QueryDocumentSnapshot } from '@firebase/firestore/lite'
import { YearMonthContractSelector } from '../selectors/YearMonthContractSelector'
import { EmployeeStatus } from '../../model/Employee'
import dayjs, { Dayjs } from 'dayjs'
import { useAdminTimeOffs } from '../../hooks/use-admin-timeoffs'
import { TimeOff, TimeOffStatus, TimeOffType } from '../../model/TimeOff'
import { usePublicHolidays } from '../../hooks/use-public-holidays'
import { useAdminTimesheet } from '../../hooks/use-admin-timesheet'
import { timesheetService } from '../../service/timesheetService'
import { useToast } from '../../hooks/use-toast'
import { defaultErrorHandler } from '../../util/errorHandler'
import { Sync } from '@mui/icons-material'
import { timeOffService } from '../../service/timeOffService'
import { LoadingButton } from '@mui/lab'
import { ExcelExport } from '../export/ExcelExport'
import { ExcelExportPTOSummary } from '../export/ExcelExportPTOSummary'
import { Timesheet } from '../../model/Timesheet'

const TitleToolbar = () =>
(
  <Box style={{
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid'
  }}
    sx={{ backgroundColor: theme => theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.background.default }}>
    <Typography variant="body2">Daily hours</Typography>
  </Box>
)

export function getTimeOffCount(timeOffs: TimeOff[], type: TimeOffType): number {
  return timeOffs?.filter(t => t.type === type && t.status === TimeOffStatus.approved)?.length ?? 0
}

export function getTotalCount(timeOffs: TimeOff[], timesheet: Timesheet[]): number {
  const workdays = timesheet?.filter(t => (!t.timeOff || (t.timeOff.status !== TimeOffStatus.approved)) && !t.publicHoliday && !isWeekend(dayjs(t.date))).length || 0
  const daysOff = timeOffs?.filter(t => t.status === TimeOffStatus.approved)?.length ?? 0
  return workdays + daysOff
}

export const EmployeeDailyHoursTable: React.FC = () => {
  const mapPageToNextCursor = React.useRef<Record<number, QueryDocumentSnapshot>>({})
  const [contractType, setContractType] = React.useState<EmployeeStatus | null>(null)
  const [divisionName, setDivisionName] = React.useState<string>()
  const [page, setPage] = React.useState(0)
  const [syncing, setSyncing] = React.useState(false)
  const [month, setMonth] = React.useState<Dayjs>(dayjs())
  const toast = useToast()
  const [pageSize, setPageSize] = React.useState<number>(20)
  const theme = useTheme()

  const queryOptions = React.useMemo(
    () => ({
      cursor: mapPageToNextCursor.current[page - 1],
      pageSize
    }),
    [page, pageSize]
  )

  const { employees, loading: loadingEmployees, nextPage, count } = useEmployees(queryOptions, contractType, divisionName)
  const {
    timeOffs,
    refetch: refetchTimeOffs,
    loading: loadingTimeOffs
  } = useAdminTimeOffs(employees.map(e => e.id), month.startOf('month').toDate(), month.endOf('month').toDate())

  const { timesheet, loading: loadingTimesheet, refetch: refetchTimesheet } =
    useAdminTimesheet(
      employees.map(e => e.id),
      month.startOf('month').toDate(),
      month.endOf('month').toDate()
    )

  const syncTimeOffs = () => {
    setSyncing(true)
    timeOffService.syncTimeOffs().then(() => {
      toast.show('success', 'Synced time offs')
    })
      .catch(err => {
        defaultErrorHandler(err, toast)
      }).finally(() => {
        refetchTimeOffs()
        setSyncing(false)
      })
  }

  const adminValidateEmployee = (eId: string) => {
    console.log('adminValidateEmployee', eId)
    toast.loading('Validating timesheet')
    timesheetService.validate(timesheet?.[eId], timeOffs?.[eId], publicHolidays)
      .then(() => {
        toast.dismiss()
        toast.show('success', 'Timesheet validated')
        refetchTimesheet()
      })
      .catch(err => {
        toast.dismiss()
        defaultErrorHandler(err, toast)
      }).finally()
  }
  const adminInvalidateEmployee = (eId: string) => {
    console.log('adminInvalidateEmployee', eId)
    toast.loading('Invalidating timesheet')
    timesheetService.invalidate(timesheet?.[eId], timeOffs?.[eId], publicHolidays)
      .then(() => {
        toast.dismiss()
        toast.show('success', 'Timesheet invalidated')
        refetchTimesheet()
      })
      .catch(err => {
        toast.dismiss()
        defaultErrorHandler(err, toast)
      })
  }

  const { publicHolidays, loading: loadingPublicHolidays } = usePublicHolidays()

  const handlePageChange = (newPage: number) => {
    // We have the cursor, we can allow the page transition.
    if (newPage === 0 || mapPageToNextCursor.current[newPage - 1]) {
      setPage(newPage)
    }
  }

  React.useEffect(() => {
    if (!loadingEmployees && nextPage) {
      // We add nextCursor when available
      mapPageToNextCursor.current[page] = nextPage
    }
  }, [page, loadingEmployees, nextPage])

  const rows = employees.map(e => ({
    id: e.employeeNumber || e.id || e.workEmail,
    fullName: [`${e.lastName} ${e.firstName}`, e.photoUrl, timesheet?.[e.id]?.every(t => t.validated) ?? false],
    valid: [timesheet?.[e.id]?.every(t => t.validated) ?? false, loadingTimesheet, e.id],
    timeframe: e.employmentHistoryStatus === 'Part-Time' ? '9:00 - 14:00' : '9:00 - 18:00',
    ...(getDaysArrayByMonth(month).reduce((acc, day) => {
      const timeOff = timeOffs[e.id]?.find(t => dayjs(t.date).isSame(day, 'day'))
      const publicHoliday = publicHolidays.find(p => dayjs(p.date).isSame(day, 'day'))
      const hasNoTimeOff = !timeOff || timeOff.status !== TimeOffStatus.approved
      const dayTimesheet = timesheet?.[e.id]?.find(t => dayjs(t.date).isSame(day, 'day'))
      const validated = dayTimesheet?.validated ?? false
      const isPartTime = e.employmentHistoryStatus === 'Part-Time'
      const isTerminated = e.employmentHistoryStatus === 'Terminated'
      return {
        ...acc,
        [day.format('Do')]: [
          hasNoTimeOff && !publicHoliday && !isWeekend(day) && !isTerminated && dayTimesheet ? isPartTime ? '4' : '8' : '0',
          isWeekend(day),
          publicHoliday,
          timeOff,
          validated,
          dayTimesheet
        ]
      }
    },
      {})),
    workDays: timesheet?.[e.id]?.filter(t => (!t.timeOff || (t.timeOff.status !== TimeOffStatus.approved)) && !t.publicHoliday && !isWeekend(dayjs(t.date))).length || 0,
    pto: getTimeOffCount(timeOffs?.[e.id], TimeOffType.PTO),
    med: getTimeOffCount(timeOffs?.[e.id], TimeOffType.MedicalLeave),
    covidMed: getTimeOffCount(timeOffs?.[e.id], TimeOffType.CovidMedicalLeave),
    mat: getTimeOffCount(timeOffs?.[e.id], TimeOffType.MaternityLeave),
    pat: getTimeOffCount(timeOffs?.[e.id], TimeOffType.PaternityLeave),
    census: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Autorecenzare),
    blood: getTimeOffCount(timeOffs?.[e.id], TimeOffType.BloodDonation),
    study: getTimeOffCount(timeOffs?.[e.id], TimeOffType.StudyLeave),
    birthday: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Birthday),
    marriage: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Marriage),
    bereavement: getTimeOffCount(timeOffs?.[e.id], TimeOffType.BereavementLeave),
    zlp: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Zlp),
    adoption: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Adoption),
    totalDays: getTotalCount(timeOffs?.[e.id], timesheet?.[e.id])
  }))

  return (
    <Box sx={{
      height: pageSize <= 10 ? 680 : pageSize < 20 ? 940 : 1200,
      '& .weekend': {
        backgroundColor: theme => theme.palette.mode === 'light' ? customColors.adminWeekend : alpha(customColors.adminWeekend, 0.3)
      },
      '& .publicHolidayCell': {
        backgroundColor: alpha(customColors.publicHolidays, 0.3)
      },
      '& .publicHolidayHeaderCell': {
        backgroundColor: customColors.publicHolidays,
        color: theme => theme.palette.getContrastText(customColors.publicHolidays)
      },
      '& .totalDaysHeader': {
        backgroundColor: customColors.success,
        color: theme => theme.palette.getContrastText(customColors.success)
      },
      '& .totalDaysCell': {
        backgroundColor: alpha(customColors.success, 0.1)
      },
      '& .workDaysHeader': {
        backgroundColor: customColors.accent,
        color: theme => theme.palette.getContrastText(customColors.accent)
      },
      '& .workDaysCell': {
        backgroundColor: alpha(customColors.accent, 0.1)
      },
      '& .ptoDaysHeader': {
        backgroundColor: customColors.pto,
        color: theme => theme.palette.getContrastText(customColors.pto)
      },
      '& .ptoDaysCell': {
        backgroundColor: alpha(customColors.pto, 0.3)
      },
      '& .medDaysHeader': {
        backgroundColor: customColors.medicalLeave,
        color: theme => theme.palette.getContrastText(customColors.medicalLeave)
      },
      '& .medDaysCell': {
        backgroundColor: alpha(customColors.medicalLeave, 0.3)
      },
      '& .covidMedDaysHeader': {
        backgroundColor: customColors.covidMedicalLeave,
        color: theme => theme.palette.getContrastText(customColors.covidMedicalLeave)
      },
      '& .covidMedDaysCell': {
        backgroundColor: alpha(customColors.covidMedicalLeave, 0.3)
      },
      '& .paternityDaysHeader': {
        backgroundColor: customColors.paternity,
        color: theme => theme.palette.getContrastText(customColors.paternity)
      },
      '& .paternityDaysCell': {
        backgroundColor: alpha(customColors.paternity, 0.3)
      },
      '& .maternityDaysHeader': {
        backgroundColor: customColors.maternity,
        color: theme => theme.palette.getContrastText(customColors.maternity)
      },
      '& .maternityDaysCell': {
        backgroundColor: alpha(customColors.maternity, 0.3)
      },
      '& .censusDaysHeader': {
        backgroundColor: customColors.census
      },
      '& .censusDaysCell': {
        backgroundColor: alpha(customColors.census, 0.3)
      },
      '& .bereavementDaysHeader': {
        backgroundColor: customColors.bereavement,
        color: theme => theme.palette.getContrastText(customColors.bereavement)
      },
      '& .bereavementDaysCell': {
        backgroundColor: alpha(customColors.bereavement, 0.3)
      },
      '& .bloodDaysHeader': {
        backgroundColor: customColors.blood,
        color: theme => theme.palette.getContrastText(customColors.blood)
      },
      '& .bloodDaysCell': {
        backgroundColor: alpha(customColors.blood, 0.3)
      },
      '& .studyDaysHeader': {
        backgroundColor: customColors.study,
        color: theme => theme.palette.getContrastText(customColors.study)
      },
      '& .studyDaysCell': {
        backgroundColor: alpha(customColors.study, 0.3)
      },
      '& .birthdayDaysHeader': {
        backgroundColor: customColors.birthDay
      },
      '& .zlpDaysHeader': {
        backgroundColor: customColors.zlp
      },
      '& .adoptionDaysHeader': {
        backgroundColor: customColors.adoption
      },
      '& .marriageDaysHeader': {
        backgroundColor: customColors.marriage,
        color: theme => theme.palette.getContrastText(customColors.marriage)
      },
      '& .marriageDaysCell': {
        backgroundColor: alpha(customColors.marriage, 0.3)
      },
      '& .birthdayDaysCell': {
        backgroundColor: alpha(customColors.birthDay, 0.3)
      },
      '& .zlpDaysCell': {
        backgroundColor: alpha(customColors.zlp, 0.3)
      },
      '& .adoptionDaysCell': {
        backgroundColor: alpha(customColors.adoption, 0.3)
      },
      '& .MuiDataGrid-columnSeparator': {
        visibility: 'hidden'
      },
      '& .MuiDataGrid-columnHeader': {
        borderLeftWidth: '1px',
        borderLeftStyle: 'solid',
        borderColor: theme.palette.mode === 'light' ? '#000000' : '#ffffff'
      },
      '& .valid-cell': {
        backgroundColor: theme.palette.mode === 'light' ? customColors.validated : alpha(customColors.validated, 0.3)
      },
      '& .valid-cell:hover': {
        backgroundColor: alpha(customColors.validated, 0.5)
      },
      '& .invalid-cell': {
        backgroundColor: theme.palette.mode === 'light' ? customColors.error2 : alpha(customColors.error2, 0.3)
      },
      '& .invalid-cell:hover': {
        backgroundColor: alpha(customColors.error2, 0.5)
      }
    }}>
      <YearMonthContractSelector onChanged={(year, month, contractType, divisionName) => {
        setContractType(contractType === 'All' ? null : contractType as EmployeeStatus)
        setMonth(dayjs(month))
        setDivisionName(divisionName === 'All' ? '' : divisionName)
      }} />
      <Stack direction="row" my={2}>
        <ExcelExport startDate={month.startOf('month')} endDate={month.endOf('month')} contractType={contractType as EmployeeStatus} divisionName={divisionName} />
        <ExcelExportPTOSummary startDate={month.startOf('month')} endDate={month.endOf('month')} contractType={contractType as EmployeeStatus} divisionName={divisionName} />
        <LoadingButton
          variant="outlined"
          endIcon={<Sync />}
          sx={{ paddingX: 5 }}
          loading={syncing}
          onClick={syncTimeOffs}>
          <Typography fontWeight={700} variant="body2">SYNC</Typography>
        </LoadingButton>
      </Stack>
      <DataGrid
        pageSize={pageSize}
        loading={loadingEmployees || loadingTimeOffs || loadingPublicHolidays || loadingTimesheet}
        sx={{
          '& .MuiDataGrid-columnSeparator': {
            color: theme => theme.palette.secondary.contrastText
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme => theme.palette.mode === 'light' ? 'secondary.main' : 'inherit'
          }
        }}
        components={
          { Toolbar: TitleToolbar }
        }
        rows={rows}
        pagination
        paginationMode="server"
        rowCount={count ?? 0}
        columns={generateDailyHoursTableColumns(month, publicHolidays, adminValidateEmployee, adminInvalidateEmployee)}
        onPageSizeChange={(size) => {
          setPageSize(size)
        }}
        rowsPerPageOptions={[10, 15, 20]}
        onPageChange={handlePageChange}
        page={page}
        initialState={{}}
      />
    </Box>
  )
}
