import { useEffect, useState } from 'react'
import { defaultErrorHandler } from '../util/errorHandler'
import { useToast } from './use-toast'
import { employeeService } from '../service/employeeService'

export const useEmployeeDivisions = (): {
  divisions: string[]
} => {
  const [divisions, setDivisions] = useState<string[]>([])
  const toast = useToast()

  useEffect(() => {
    const fetchEmployeeDivisions = async () => {
      try {
        const employeeDivisions = await employeeService.getUniqueDivisions()
        setDivisions(['All', ...employeeDivisions])
      } catch (error) {
        defaultErrorHandler(error, toast)
      } finally { /* empty */ }
    }

    fetchEmployeeDivisions()
  }, [])

  return {
    divisions
  }
}
