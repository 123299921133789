import { Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import CsvDownloader from 'react-csv-downloader'
import { employeeService } from '../../service/employeeService'
import { defaultErrorHandler } from '../../util/errorHandler'
import { useToast } from '../../hooks/use-toast'
import { timesheetService } from '../../service/timesheetService'
import dayjs, { Dayjs } from 'dayjs'
import { groupTimesheetByEmployeeId } from '../../hooks/use-admin-timesheet'
import { Timesheet } from '../../model/Timesheet'
import { isWeekend } from '../../util/dateUtils'
import { TimeOffStatus, TimeOffType } from '../../model/TimeOff'
import { getTimeOffCount } from '../admin/EmployeeDailyHoursTable'
import { useAdminTimeOffs } from '../../hooks/use-admin-timeoffs'
import { useEmployees } from '../../hooks/use-employees'
import { EmployeeStatus } from '../../model/Employee'

const generateColumns = (startDate: Dayjs) => [
  { displayName: 'Id', id: 'id' },
  { displayName: 'Full Name', id: 'name' },
  { displayName: 'Work Days', id: 'workDays' },
  { displayName: 'Total Days Off', id: 'daysOff' },
  { displayName: 'PTO', id: 'pto' },
  { displayName: 'Medical Leave', id: 'med' },
  { displayName: 'Covid Medical Leave', id: 'covidMed' },
  { displayName: 'Maternity Leave', id: 'mat' },
  { displayName: 'Paternity Leave', id: 'pat' },
  { displayName: 'Autorecenzare', id: 'census' },
  { displayName: 'Blood Donation', id: 'blood' },
  { displayName: 'Study Leave', id: 'study' },
  { displayName: 'Birthday', id: 'birthday' },
  { displayName: 'Marriage', id: 'marriage' },
  { displayName: 'Bereavement Leave', id: 'bereavement' },
  { displayName: 'ZLP', id: 'zlp' },
  { displayName: 'Adoption', id: 'adoption' }
]

function splitEmployeesIntoGroups<T>(array: T[], size: number = 10): T[][] {
  const arrays = [] as T[][]
  const a = [...array]

  while (a.length > 0) {
    arrays.push(a.splice(0, size))
  }

  return arrays
}

export const ExcelExportPTOSummary: React.FC<{ startDate: Dayjs, endDate: Dayjs, contractType?: string, divisionName?: string }> = ({ startDate, endDate, contractType, divisionName }) => {
  const toast = useToast()
  const now = dayjs()
  const [loading, setLoading] = useState(false)
  const { employees } = useEmployees()
  const { timeOffs } = useAdminTimeOffs(employees.map(e => e.id), startDate.toDate(), endDate.toDate())
  const handleExport = async (): Promise<any[]> => {
    const data: any[] = []

    try {
      setLoading(true)
      const { employees } = await employeeService.getActiveEmployees(contractType as EmployeeStatus, divisionName)
      const employeeGroups = splitEmployeesIntoGroups(employees)
      const allEmployeeTimesheet: Record<string, Timesheet[]> = {}
      for (const group of employeeGroups) {
        const timesheet = await timesheetService.retrieveTimeSheetForEmployees(
          group?.map(e => e.id),
          startDate.toDate(),
          endDate.toDate()
        )
        const groupedTimesheet = groupTimesheetByEmployeeId(timesheet)
        Object.assign(allEmployeeTimesheet, groupedTimesheet)
      }

      employees.forEach(e => {
        const row = {
          id: e.employeeNumber,
          name: `${e.lastName} ${e.firstName}`,
          workDays: allEmployeeTimesheet?.[e.id]?.filter(t => !t.timeOff && !t.publicHoliday && !isWeekend(dayjs(t.date))).length || 0,
          daysOff: timeOffs?.[e.id]?.filter(t => t.status === TimeOffStatus.approved).length || 0,
          pto: getTimeOffCount(timeOffs?.[e.id], TimeOffType.PTO),
          med: getTimeOffCount(timeOffs?.[e.id], TimeOffType.MedicalLeave),
          covidMed: getTimeOffCount(timeOffs?.[e.id], TimeOffType.CovidMedicalLeave),
          mat: getTimeOffCount(timeOffs?.[e.id], TimeOffType.MaternityLeave),
          pat: getTimeOffCount(timeOffs?.[e.id], TimeOffType.PaternityLeave),
          census: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Autorecenzare),
          blood: getTimeOffCount(timeOffs?.[e.id], TimeOffType.BloodDonation),
          study: getTimeOffCount(timeOffs?.[e.id], TimeOffType.StudyLeave),
          birthday: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Birthday),
          marriage: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Marriage),
          bereavement: getTimeOffCount(timeOffs?.[e.id], TimeOffType.BereavementLeave),
          zlp: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Zlp),
          adoption: getTimeOffCount(timeOffs?.[e.id], TimeOffType.Adoption)
        }
        data.push(row)
      })
    } catch (e) {
      defaultErrorHandler(e, toast)
    } finally {
      setLoading(false)
    }
    return data
  }

  const columns = useMemo(() => generateColumns(startDate), [startDate])
  return (
    <CsvDownloader filename={`export_${startDate.format('MMMM')}_${now.format('DD_MM_YY-HH_mm')}`}
      datas={handleExport} columns={columns}>
      <LoadingButton
        loading={loading}
        variant="contained"
        sx={{
          paddingY: 1,
          paddingX: 3,
          '& a': {
            textDecoration: 'none',
            color: theme => theme.palette.primary.contrastText
          }
        }}
      >
        <Typography variant="body2" fontWeight={700}>EXPORT PTOs</Typography>
      </LoadingButton>
    </CsvDownloader>
  )
}
