import { collection, collectionGroup, getDocs, query, where } from 'firebase/firestore'
import { db } from '../config/firebase'
import { TimeOff, timeOffConverter } from '../model/TimeOff'
import { getFunctions, httpsCallable } from 'firebase/functions'

export const timeOffService = {
  fetchEmployeeTimeOffs: async (employeeId: string): Promise<TimeOff[]> => {
    return await new Promise<TimeOff[]>((resolve, reject) => {
      const fetchEmployeeTimeOffsFc = async () => {
        if (!employeeId) {
          resolve([])
          return
        }

        const q = query(collection(db, `employees/${employeeId}/timeOffs`).withConverter(timeOffConverter))

        getDocs(q).then((querySnapshot) => {
          const timeOffs: TimeOff[] = []
          querySnapshot.forEach((doc) => {
            timeOffs.push(doc.data())
          })
          resolve(timeOffs)
        }).catch((error) => {
          reject(error)
        })
      }

      fetchEmployeeTimeOffsFc()
    })
  },
  async retrieveTimeOffsForEmployees (
    employeeIds: string[],
    startDate: Date,
    endDate: Date) {
    return await new Promise<TimeOff[]>((resolve, reject) => {
      const retrieveTimeOffsForEmployeesFc = async () => {
        try {
          const timeOffs: TimeOff[] = []

          const chunkSize = 10
          for (let i = 0; i < employeeIds.length; i += chunkSize) {
            const employeeChunk = employeeIds.slice(i, i + chunkSize)
            console.log('employeeChunk', employeeChunk)
            const docs = await getDocs(
              query(
                collectionGroup(db, 'timeOffs')
                  .withConverter(timeOffConverter),
                where('employeeId', 'in', employeeChunk),
                where('date', '>=', startDate),
                where('date', '<=', endDate)
              ))

            docs.forEach((doc) => {
              timeOffs.push(doc.data())
            })
          }

          resolve(timeOffs)
        } catch (error) {
          reject(error)
        }
      }
      retrieveTimeOffsForEmployeesFc()
    })
  },
  async syncTimeOffs (): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      const syncTimeOffsFc = async () => {
        try {
          await httpsCallable(getFunctions(), 'retrieveTimeOffsFromBambooHR')()
          resolve()
        } catch (error) {
          reject(error)
        }
      }
      syncTimeOffsFc()
    })
  }
}
