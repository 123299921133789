import { useEffect, useState } from 'react'
import { Employee, EmployeeStatus } from '../model/Employee'
import { defaultErrorHandler } from '../util/errorHandler'
import { employeeService } from '../service/employeeService'
import { QueryDocumentSnapshot } from '@firebase/firestore/lite'
import { useToast } from './use-toast'

export const useEmployees = (
  options?: { cursor: QueryDocumentSnapshot, pageSize: number },
  contractType?: EmployeeStatus | null,
  divisionName?: string | ''):
{
  employees: Employee[]
  loading: boolean
  nextPage: QueryDocumentSnapshot | null
  count: number
} => {
  const { cursor, pageSize } = options ?? {}
  const [employees, setEmployees] = useState<Employee[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [nextPage, setNextPage] = useState<QueryDocumentSnapshot | null>(null)
  const [count, setCount] = useState<number>(0)
  const toast = useToast()

  const getEmployees = async () => {
    try {
      setLoading(true)
      const { employees, nextPage, count } = await employeeService.getActiveEmployees(contractType, divisionName, cursor, pageSize)
      setEmployees(employees)
      setNextPage(nextPage)
      setLoading(false)
      setCount(count)
    } catch (error) {
      defaultErrorHandler(error, toast)
    }
  }

  useEffect(() => {
    getEmployees()
  }, [cursor, pageSize, contractType, divisionName])

  return {
    employees,
    loading,
    nextPage,
    count
  }
}
