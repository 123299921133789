import { useEffect, useState } from 'react'
import { defaultErrorHandler } from '../util/errorHandler'
import { useToast } from './use-toast'
import { usePrevious } from './use-previous'
import { TimeOff } from '../model/TimeOff'
import { timeOffService } from '../service/timeOffService'
import dayjs from 'dayjs'

export const useAdminTimeOffs = (
  employeeIds: string[],
  startDate: Date,
  endDate: Date
):
  {
    timeOffs: Record<string, TimeOff[]>
    refetch: () => void
    loading: boolean
  } => {
  const [timeOffs, setTimeOffs] = useState<Record<string, TimeOff[]>>({})
  const previousEmployeeIds = usePrevious(employeeIds)
  const previousStartDate = usePrevious(startDate)
  const previousEndDate = usePrevious(endDate)

  const [loading, setLoading] = useState<boolean>(false)
  const toast = useToast()

  const fetchTimeOffsForEmployees = () => {
    timeOffService
      .retrieveTimeOffsForEmployees(employeeIds, startDate, endDate)
      .then((timeOffs) => {
        const employeeTimeOffs: Record<string, TimeOff[]> = {}
        timeOffs.forEach((timeOff) => {
          if (!employeeTimeOffs[timeOff.employeeId]) {
            employeeTimeOffs[timeOff.employeeId] = []
          }
          employeeTimeOffs[timeOff.employeeId].push(timeOff)
        })
        setTimeOffs(employeeTimeOffs)
      })
      .catch((err) => {
        defaultErrorHandler(err, toast)
      }).finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (previousEmployeeIds &&
        previousEmployeeIds.length === employeeIds.length &&
        previousEmployeeIds.every(
          (id, index) => id === employeeIds[index]
        )) {
      const previousStartDateMoment = dayjs(previousStartDate)
      const previousEndDateMoment = dayjs(previousEndDate)
      if (previousStartDateMoment.isSame(startDate, 'day') && previousEndDateMoment.isSame(endDate, 'day')) {
        // no work to do
        return
      }
    }

    if (employeeIds?.length === 0 || !startDate || !endDate) {
      return
    }

    setLoading(true)
    fetchTimeOffsForEmployees()
  }, [employeeIds, startDate, endDate])

  return {
    timeOffs,
    refetch: fetchTimeOffsForEmployees,
    loading
  }
}
