import React from 'react'
import { Box, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { customColors } from '../../theme'

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    height: '32.75px',
    color: theme.palette.mode === 'light' ? 'black' : 'white',
    marginRight: theme.spacing(2),
    border: '2px solid ' + customColors.primary,
    borderRadius: theme.shape.borderRadius,
    boxSizing: 'bordder-box',
    '&.Mui-disabled': {
      border: 1
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
      border: '2px solid ' + customColors.primary,
      '&.Mui-selected': {
        background: customColors.primary,
        color: 'white',
        border: '2px solid white',
        outline: '2px solid ' + customColors.primary
      }
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
      border: '2px solid ' + customColors.primary,
      '&.Mui-selected': {
        background: customColors.primary,
        color: 'white',
        border: '2px solid white',
        outline: '2px solid ' + customColors.primary
      }
    }
  }
}))

const Selector: React.FC<{
  options: string[]
  label?: string
  selected: string
  onChange: (value: string) => void
}> = ({ label, options, selected, onChange }) => {
  const handleSelected = (
    event: React.MouseEvent<HTMLElement>,
    newSelected: string
  ) => {
    if (newSelected !== null) onChange(newSelected)
  }
  return (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          margin: 2
        }}>
            {
                label &&
                <Typography variant="body2" fontWeight="bold"
                            sx={{ marginRight: theme => theme.spacing(2) }}>{label}</Typography>
            }
            <StyledToggleButtonGroup
                size="small"
                value={selected}
                exclusive
                onChange={handleSelected}
                aria-label="selected group"
            >
                {options.map((thisSelected) => (
                    <ToggleButton key={thisSelected} value={thisSelected}>{thisSelected}</ToggleButton>
                ))}
            </StyledToggleButtonGroup>
        </Box>
  )
}

export default Selector
