import React, { useEffect } from 'react'

export const Page = ({
  children,
  title
}: { children: React.ReactNode, title: string }) => {
  useEffect(() => {
    document.title = title
  })
  return <>{children}</>
}
