import Timesheet from '../screens/TimeSheet'
import Login from '../screens/Login'
import { Dashboard } from '../screens/Dashboard'

const routes = [
  {
    path: '/timesheet',
    component: Timesheet,
    name: 'Timesheet',
    protected: true
  },
  {
    path: '/dashboard',
    component: Dashboard,
    name: 'Dashboard',
    protected: true
  },
  {
    path: '/login',
    component: Login,
    name: 'Login Screen',
    protected: false
  }
]

export default routes
