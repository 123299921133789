import {
  CalendarPickerSkeleton,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
  StaticDatePicker
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { alpha, Box, darken, Paper, styled, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { Timesheet } from '../../model/Timesheet'
import { TimeOff } from '../../model/TimeOff'
import { customColors } from '../../theme'
import { PublicHoliday } from '../../model/PublicHoliday'
import { findTimeOff, findTimesheet, getTimeOffColor, getTooltipTitle } from './employeeCalendarUtils'
import { isWeekend } from '../../util/dateUtils'

interface StyledPickersDayProps extends PickersDayProps<Dayjs> {
  dayIsSelected: boolean
  isFirstDay: boolean
  isLastDay: boolean
  dayIsValidated: boolean
  dayIsPublicHoliday: boolean
  timeOff?: TimeOff
}

const StyledPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsSelected' &&
        prop !== 'isFirstDay' &&
        prop !== 'isLastDay' &&
        prop !== 'dayIsValidated' &&
        prop !== 'dayIsPublicHoliday' &&
        prop !== 'timeOff'
})<StyledPickersDayProps>(({
  theme,
  dayIsSelected,
  dayIsPublicHoliday,
  timeOff
}) => ({
  backgroundColor: 'transparent',
  ...(dayIsSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: darken(theme.palette.primary.dark, 0.1)
    }
  }),
  ...(dayIsPublicHoliday && {
    backgroundColor: customColors.publicHolidays,
    color: theme.palette.getContrastText(customColors.publicHolidays),
    '&:hover, &:focus': {
      backgroundColor: darken(customColors.publicHolidays, 0.1)
    }
  }),
  ...(!!timeOff && {
    backgroundColor: getTimeOffColor(timeOff),
    color: theme.palette.getContrastText(getTimeOffColor(timeOff)),
    '&:hover, &:focus': {
      backgroundColor: darken(getTimeOffColor(timeOff), 0.1)
    }
  })
})) as React.ComponentType<StyledPickersDayProps>

interface CustomPickersDayProps {
  publicHoliday?: PublicHoliday
  timeOff?: TimeOff
  lastTimesheetDate?: Dayjs | null
}

const CustomPickersDay: React.FC<StyledPickersDayProps & CustomPickersDayProps> = (props) => {
  const { dayIsSelected, isFirstDay, isLastDay, dayIsValidated, timeOff, lastTimesheetDate, ...other } = props
  delete other.publicHoliday
  const tooltipTitle = getTooltipTitle(props.publicHoliday, dayIsValidated, isWeekend(props.day), timeOff)

  const isFirstValidatedDate = dayjs('2023-01-01').isSame(props.day, 'day') && !isFirstDay
  const isLastValidatedDate = (lastTimesheetDate?.subtract(1, 'day')?.isSame(props.day, 'day') ??
        dayjs().isSame(props.day, 'day')) && !isLastDay
  return (
        <Box sx={{
          backgroundColor: theme => theme.palette.background.paper,
          p: 0.25,
          ...(dayIsSelected && {
            borderRadius: 0,
            backgroundColor: theme => theme.palette.primary.main,
            color: theme => theme.palette.common.white
          }),
          ...(isFirstDay && {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%'
          }),
          ...(isLastDay && {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%'
          }),
          ...(dayIsValidated && {
            backgroundColor: alpha(customColors.validatedCalendar, 0.3),
            color: theme => theme.palette.text.primary
          }),
          ...(isFirstValidatedDate && {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%'
          }),
          ...((isLastValidatedDate && !dayIsSelected) && {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%'
          })
        }}>
            <Tooltip title={tooltipTitle}>
                <StyledPickersDay {...other} dayIsSelected={dayIsSelected} isFirstDay={isFirstDay} onFocus={() => {
                }}
                                  isLastDay={isLastDay} dayIsValidated={dayIsValidated} timeOff={timeOff}/>
            </Tooltip>
        </Box>
  )
}

export const EmployeeCalendar: React.FC<{
  timesheet: Timesheet[]
  timeOffs: TimeOff[]
  publicHolidays: PublicHoliday[]
  loading: boolean
}> = ({ timesheet, loading, timeOffs, publicHolidays }) => {
  const minDate = dayjs('2023-01-01')
  const lastTimesheetDate = timesheet?.[timesheet.length - 1]?.date ? dayjs(timesheet?.[timesheet.length - 1]?.date) : null

  const renderPickerDay = (
    date: Dayjs,
    selectedDates: Array<Dayjs | null>,
    pickersDayProps: PickersDayProps<Dayjs>
  ) => {
    const outsideStyle = pickersDayProps.outsideCurrentMonth ? { opacity: 0.4 } : {}
    const sx = { ...pickersDayProps.sx, ...outsideStyle }

    if (date.isAfter(dayjs(), 'day')) {
      return <PickersDay {...pickersDayProps} sx={sx} />
    }
    const dayTimesheet = findTimesheet(date, timesheet)
    const timeOff = findTimeOff(date, timeOffs)
    const dayIsSelected = !!dayTimesheet
    const isFirstDay = timesheet?.[0] ? date.isSame(timesheet[0].date, 'day') : false
    const isLastDay = lastTimesheetDate ? date.isSame(lastTimesheetDate, 'day') : false
    const dayIsValidated = !dayTimesheet
    const publicHoliday = publicHolidays.find((publicHoliday) => dayjs(publicHoliday.date).isSame(date, 'day'))

    return (
            <CustomPickersDay
                {...pickersDayProps}
                dayIsPublicHoliday={!!publicHoliday}
                disableMargin
                dayIsSelected={dayIsSelected}
                dayIsValidated={dayIsValidated}
                publicHoliday={publicHoliday}
                timeOff={timeOff}
                lastTimesheetDate={lastTimesheetDate}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
                sx={sx}
            />
    )
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  if (isMobile) console.log(isMobile, true)

  return (
    <>
    <Typography color="primary" variant="h4" fontWeight="700" sx={{ marginBottom: 2, marginLeft: isMobile ? 0.5 : 0 }}>CALENDAR</Typography>
        <Paper sx={{
          ...isMobile && {
            width: '90vw',
            overflow: 'scroll'
          },
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.paper : customColors.calendarDarkBackground,
          ...(theme.palette.mode === 'dark' && { backgroundImage: 'inherit' }),
          padding: isMobile ? 0 : 2,
          borderRadius: '10.175px',
          maxWidth: isMobile ? null : '420px'
        }}
               elevation={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                    minDate={minDate}
                    maxDate={lastTimesheetDate ?? dayjs()}
                    openTo="day"
                    disabled={false}
                    displayStaticWrapperAs="desktop"
                    value={null}
                    shouldDisableDate={isWeekend}
                    onChange={() => {
                    }}
                    renderDay={renderPickerDay}
                    loading={loading}
                    renderLoading={() => <CalendarPickerSkeleton/>}
                    renderInput={(params) => <TextField {...params} />}
                    showDaysOutsideCurrentMonth
                />
            </LocalizationProvider>
        </Paper>
        </>
  )
}
