import { User } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { auth } from '../config/firebase'
import { useToast } from './use-toast'
import { defaultErrorHandler } from '../util/errorHandler'

export const useEmployeeId = (user: User | null): string | null => {
  const [employeeId, setEmployeeId] = useState<string | null>(null)
  const toast = useToast()

  useEffect(() => {
    const computeEmployeeId = async () => {
      if (user) {
        try {
          const token = await auth?.currentUser?.getIdTokenResult(true)
          if (token?.claims?.eid) {
            setEmployeeId(token.claims.eid)
          } else {
            // retry after half second
            setTimeout(() => {
              computeEmployeeId()
            }, 500)
          }
        } catch (error) {
          defaultErrorHandler(error, toast)
        }
      } else {
        setEmployeeId(null)
      }
    }

    computeEmployeeId()
  }, [user])

  return employeeId
}
