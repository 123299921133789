import { Box, Stack } from '@mui/material'
import React from 'react'
import Typography from '@mui/material/Typography'

export const Logo: React.FC<{ size?: 'small' | 'medium', color?: string }> = ({ size, color }) => {
  const variant = size === 'small' ? 'h3' : 'h1'
  return (
      <Stack direction="row" alignItems="center">
        <Typography variant={variant} fontWeight="bold" sx={{ color: color ?? 'inherit' }}>A</Typography>
        <Box alignSelf="flex-start" sx={{
          marginTop: size === 'small' ? 0.9 : 1.5,
          backgroundColor: theme => theme.palette.primary.main,
          borderRadius: '50%',
          height: size === 'small' ? 7 : 14,
          width: size === 'small' ? 7 : 14
        }}/>
        <Typography variant={variant} fontWeight="bold" sx={{ color: color ?? 'inherit' }}>Cl</Typography>
        <Box alignSelf="flex-end" sx={{
          backgroundColor: theme => theme.palette.primary.main,
          borderRadius: '50%',
          height: size === 'small' ? 20 : 40,
          width: size === 'small' ? 20 : 40,
          marginBottom: size === 'small' ? 0.9 : 1.5
        }}/>
        <Typography variant={variant} fontWeight="bold" sx={{ color: color ?? 'inherit' }}>ck</Typography>
      </Stack>
  )
}
